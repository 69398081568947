<template>
    <div class="div">
        <div class="left">
            <div class="name">
                <img
                    class="name-img"
                    v-if="userInfo.HEAD_SCULPTURE"
                    :src="userInfo.HEAD_SCULPTURE"
                    @mouseenter="MoveinImage"
                    @mouseleave="RemoveImage"
                    alt=""
                />
                <img
                    class="name-img"
                    v-if="
                        (userInfo.HEAD_SCULPTURE == null ||
                            userInfo.HEAD_SCULPTURE == '') &&
                        userInfo.SEX == 2
                    "
                    src="https://s1.ax1x.com/2022/11/22/z1ZJtH.png"
                    @mouseenter="MoveinImage"
                    @mouseleave="RemoveImage"
                    alt=""
                />
                <img
                    class="name-img"
                    v-if="
                        (userInfo.HEAD_SCULPTURE == null ||
                            userInfo.HEAD_SCULPTURE == '') &&
                        userInfo.SEX == 1
                    "
                    @mouseenter="MoveinImage"
                    @mouseleave="RemoveImage"
                    src="https://s1.ax1x.com/2022/12/08/z2YeTf.png"
                    alt=""
                />
                <img
                    class="name-img"
                    v-if="
                        (userInfo.HEAD_SCULPTURE == null ||
                            userInfo.HEAD_SCULPTURE == '') &&
                        userInfo.SEX == 0
                    "
                    src="https://s1.ax1x.com/2023/01/09/pSeuZl9.png"
                    @mouseenter="MoveinImage"
                    @mouseleave="RemoveImage"
                    alt=""
                />
                <!-- 老师名字 -->
                <div class="school">
                    <span style="font-size: 14px"
                        >{{ userInfo.P_NAME }}老师</span
                    >
                    <span>{{
                        userInfo.PARENT_ORG_INFO
                            ? userInfo.PARENT_ORG_INFO.ORG_NAME
                            : " "
                    }}</span>
                </div>
                <!-- 退出登录 -->
                <div
                    class="signoutdiv"
                    v-if="information"
                    @mouseenter="MoveinImage"
                    @mouseleave="RemoveImage"
                >
                    <div class="signout">
                        <div
                            :class="
                                item.type == false
                                    ? 'signout-div'
                                    : 'signout-divclick'
                            "
                            v-for="(item, index) in informationdat"
                            :key="item.name"
                            @mouseenter="MoveinSignout(index)"
                            @mouseleave="RemoveSignout(index)"
                            @click="item.name == '退出登录' ? Logout() : ''"
                        >
                            <div class="image">
                                <img
                                    :src="item.imagemouseenter"
                                    alt=""
                                    v-if="item.type == false"
                                />
                                <img
                                    :src="item.imagemouseleave"
                                    alt=""
                                    v-if="item.type == true"
                                />
                            </div>
                            <span class="text">{{ item.name }}</span>
                        </div>
                        <!-- <div class="signout-div">
                                <div class="image">
                                    <img
                                        src="https://s1.ax1x.com/2023/01/13/pSKf7EF.png"
                                        alt=""
                                    />
                                </div>
                                <span class="text">个人信息</span>
                            </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="security">
            <div></div>
            <!-- 天气，地区，日历 -->
            <div class="region">
                <!-- 地区位置 -->
                <div class="region-location" v-if="false">
                    <span class="city">{{ city }}</span>
                    <span class="margin_top">{{ district }}</span>
                </div>
                <!-- 天气显示 -->
                <div class="weather" v-if="false">
                    <span class="city">{{ weatherImg }}</span>
                    <span class="margin_top">{{ low }} ~ {{ high }}</span>
                </div>
                <!-- 农历时间 -->
                <div class="date">
                    <span class="city">{{ date }}</span>
                    <span class="date-rl margin_top"
                        >农历{{ getLunarDay.dateStr }}</span
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { location } from "./js/map";
import { getLunar } from "chinese-lunar-calendar";
export default {
    data() {
        return {
            lat: "",
            lng: "",
            province: "",
            city: "成都市",
            district: "",
            // 天气
            weatherData: [],
            weatherImg: "",
            high: "",
            low: "",
            // 时间
            date: "",
            // 农历
            getLunarDay: "",
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            date: new Date().getDate(),

            // 用户信息
            userInfo: JSON.parse(localStorage.getItem("userInfo")) || [],

            // 是否显示信息卡片
            information: false,
            // 各种信息
            informationdat: [
                {
                    imagemouseenter:
                        "https://s1.ax1x.com/2023/01/13/pSKf7EF.png",
                    imagemouseleave:
                        "https://s1.ax1x.com/2023/01/13/pSKfoHU.png",
                    name: "退出登录",
                    type: false,
                },
            ],
        };
    },
    beforeMount() {
        setTimeout(() => {
            this.userInfo = JSON.parse(localStorage.getItem("userInfo")) || [];
            console.log(this.userInfo, "userInfo");
        }, 1000);
    },
    mounted() {
        // 获取农历
        this.getLunarDay = getLunar(this.year, this.month, this.date);
        this.function(); //获取时间
    },
    methods: {
        /**获取地图定位*/
        getLocation() {
            let _that = this;
            let geolocation = location.initMap("map-container"); //定位
            AMap.event.addListener(geolocation, "complete", (result) => {
                _that.lat = result.position.lat;
                _that.lng = result.position.lng;
                _that.province = result.addressComponent.province;
                _that.city = result.addressComponent.city;
                _that.district = result.addressComponent.district;
            });
        },
        // 获取时间
        function() {
            let aData = new Date();
            console.log(aData); //Wed Aug 21 2019 10:00:58 GMT+0800 (中国标准时间)

            this.date =
                aData.getFullYear() +
                "-" +
                (aData.getMonth() + 1) +
                "-" +
                aData.getDate();
            // console.log(this.date, "this.data"); //2019-8-20
        },
        //获取天气数据
        created() {
            let _that = this;
            var weatherData = "";
            this.$axios
                .get(`http://wthrcdn.etouch.cn/weather_mini?city=${_that.city}`)
                .then(function (response) {
                    console.log(response.data.data, "response.data.data");
                    weatherData = response.data.data.forecast[0].type;
                    console.log(weatherData, "weatherData");
                    _that.weatherImg = weatherData;
                    _that.weatherData = response.data.data.forecast;
                    _that.low =
                        response.data.data.forecast[0].low.split(" ")[1];
                    _that.high =
                        response.data.data.forecast[0].high.split(" ")[1];
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        // 鼠标移入头像触发
        MoveinImage() {
            this.information = true;
        },
        RemoveImage() {
            this.information = false;
        },
        // 鼠标移入头像触发高亮
        MoveinSignout(e) {
            this.informationdat.map((item, index) => {
                if (index == e) {
                    item.type = true;
                }
            });
        },
        RemoveSignout(e) {
            this.informationdat.map((item, index) => {
                if (index == e) {
                    item.type = false;
                }
            });
        },
        // 退出登录
        Logout() {
            this.$router.push("/login");
            this.$message({
                message: "退出成功！",
                type: "success",
            });
            localStorage.clear();
            localStorage.setItem("warninglength", 0);
        },
    },
};
</script>

<style scoped lang='scss'>
@import "../style/_style.scss";
.div {
    display: flex;
    letter-spacing: 3px;
}
.left {
    width: 20%;
    min-width: 330px;
    max-width: 573px;
    display: flex;
    background: #21af73;
    color: #ffffff;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0px 24px 0px 34px;
}
.name {
    padding-top: 16px;
    box-sizing: border-box;
}
.name-img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: #ffffff;
}
.left span {
    vertical-align: middle;
    margin-left: 20px;
}
.school {
    vertical-align: middle;
    display: inline-block;
    height: 77px;
    font-size: 12px;
}
.school span {
    text-align: left;
    display: block;
    height: 25px;
}
/* 安全防控 */
.security {
    display: flex;
    color: #ffffff;
    padding: 0px 40px 0px 20px;
    box-sizing: border-box;
    background-image: linear-gradient(to right, #21af73, #48be8c);
    flex: 1;
    justify-content: space-between;
    min-width: 780px;
}
.control {
    font-size: 20px;
    height: 77px;
    line-height: 77px;
}
/* 地区，日期，天气 */
.region {
    display: flex;
}
.region div {
    width: 200px;
    padding-top: 12px;
    box-sizing: border-box;
}
.region .region-location {
    width: 130px;
}
.region-location span {
    display: inline-block;
    width: 100%;
    font-size: 14px;
}
.city {
    font-size: 20px !important;
}
.margin_top {
    margin-top: 7px;
}
/* 天气 */
.weather span {
    display: inline-block;
    width: 100%;
}
/* 日期 */
.region .date {
    width: 150px;
}
.date span {
    display: inline-block;
    width: 100%;
}
.date-rl {
    font-size: 14px;
}
/* 退出登录 */
.signoutdiv {
    width: 150px;
    position: absolute;
    top: 55px;
    left: 35px;
    z-index: 20;
    box-sizing: border-box;
    padding-top: 15px;
    text-align: center;
    .signout {
        width: 150px;
        height: 140px;
        background-color: #ffffff;
        box-shadow: 0px 0px 10px 2px rgb(199, 183, 183);
        border-radius: 10px;
        color: #000000;
        box-sizing: border-box;
        padding: 10px 0px;
        .signout-divclick {
            width: 88%;
            height: 25px;
            line-height: 25px;
            background-color: $colorlv;
            margin: 0 auto;
            border-radius: 8px;
            margin-bottom: 10px;
            .image {
                width: 20px;
                height: 25px;
                vertical-align: middle;
                display: inline-block;
                img {
                    width: 13px;
                    height: 10px;
                }
            }
            .text {
                display: inline-block;
                width: 80px;
                text-align: left;
                font-size: 12px;
                vertical-align: middle;
                margin-left: 10px;
                color: #ffffff;
            }
        }
        .signout-div {
            width: 88%;
            height: 25px;
            line-height: 25px;
            margin: 0 auto;
            border-radius: 8px;
            margin-bottom: 10px;
            .image {
                width: 20px;
                height: 25px;
                margin-top: -2px;
                vertical-align: middle;
                display: inline-block;
                img {
                    width: 13px;
                    height: 10px;
                }
            }
            .text {
                display: inline-block;
                width: 80px;
                text-align: left;
                font-size: 12px;
                vertical-align: middle;
                margin-left: 10px;
                color: #000000;
            }
        }
    }
}
</style>