<template>
    <div id="app">
        <Container></Container>
    </div>
</template>

<script>
import _ from "lodash";
import Container from "./components/Container.vue";

export default {
    name: "app",
    components: {
        Container,
    },
    created() {},
    methods: {},
};
</script>

<style>
html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    /* element-ui 字体设置 */
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
        "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}
#app,
.box {
    height: 100%;
}
/* 取消滚动条 */
::-webkit-scrollbar {
    width: 0;
}
::-webkit-scrollbar {
    width: 0;
    height: 0;
}
/* span 标签 */
span,
div {
    cursor: default;
}
/* 裁剪样式 */
.cropper-view-box,
.cropper-face {
    border-radius: 50%;
    border: 1px dashed #ccc;
    outline: 0px solid rgb(255, 255, 255) !important;
    box-sizing: border-box;
}
.cropper-view-box {
    border: 0px dashed #ccc !important;
    outline: 1px dashed #ccc !important;
}
.cropper-face {
    border: none;
}
.crop-info {
    display: none;
}
.vue-cropper {
    background-image: url("https://s1.ax1x.com/2023/01/17/pS1wlJP.png") !important;
    background-size: 100% 100%;
}
</style>
