import omaha_net from './axios.js'

class omaha_api {
    post(url, param, cb) {
        omaha_net.post(url, param, response => { cb(response.data); });
    }
    // 图片上传接口
    post2(url, param, cb) {
        omaha_net.post2(url, param, response => { cb(response.data); });
    }
    // 图片上传接口  2.0
    post3(url, param, cb) {
        omaha_net.post3(url, param, response => { cb(response.data); });
    }
    get(url, cb) {
        omaha_net.get(url, response => { cb(response.data); });
    }
    postlogin(url, param, cb) {
        omaha_net.postlogin(url, param, response => { cb(response); });
    }
    // 通用查询数据字典
    EduQueryByDicgroupDropDown(data, cb) {
        var param = data;
        let url = `Query/edu_query_by_dicgroup_drop_down`;
        this.post(url, param, cb);
    }

    // 删除图片
    delimage(data, cb) {
        var param = data;
        let url = `File/del/` + param;
        this.get(url, cb);
    }
    delimage2(data, cb) {
        var param = data;
        let url = `FileNginx/UploadFileLocation`;
        this.post(url, param, cb);
    }

    // 登录
    login(data, cb) {
        var param = data;
        let url = `login`;
        this.postlogin(url, param, cb);
    }

    // 考勤页面
    // 考勤页面 考勤学校树形下拉列表
    SchoolTreeDropdownList(data, cb) {
        var param = data;
        let url = `Business/SchoolTreeDropdownList`;
        this.post(url, param, cb);
    }
    // 考勤页面 动态数据
    QueryClassroomAttendanceDynamicData(data, cb) {
        var param = data;
        let url = `Business/QueryClassroomAttendanceDynamicData`;
        this.post(url, param, cb);
    }

    // 获取头像
    getFile(data, cb) {
        var param = data;
        let url = `File/get/` + param;
        this.get(url, cb);
    }
    // 上传图片 1.0
    addimage(data, cb) {
        console.log(data, 'data');
        var param = data;
        let url = `File/upload`;
        this.post2(url, param, cb);
    }
    // 上传图片 2.0
    ImportFile(data, cb) {
        console.log(data, 'data上传图片');
        var param = data;
        let url = `Import/ImportFile`;
        this.post3(url, param, cb);
    }
    // 部门
    // 查询部门
    getbypid(data, cb) {
        var param = data;
        let url = `Query/query_org_info_bypid`;
        this.post(url, param, cb);
    }
    // 新增组织机构
    orginfo(data, cb) {
        var param = data;
        let url = `DBObject/new/org_info`;
        this.post(url, param, cb);
    }
    // 编辑组织机构
    editconfirm(data, cb) {
        var param = data;
        let url = `DBObject/update/org_info`;
        this.post(url, param, cb);
    }
    // 部门删除
    GeneralDepartmentDeletion(data, cb) {
        var param = data;
        let url = `Business/GeneralDepartmentDeletion`;
        this.post(url, param, cb);
    }

    // 人员页面
    // 新增职员
    addpersonnel(data, cb) {
        var param = data;
        let url = `DBObject/new/sys_personnel`;
        this.post(url, param, cb);
    }
    // 修改职员
    updatepersonnel(data, cb) {
        var param = data;
        let url = `DBObject/update/sys_personnel`;
        this.post(url, param, cb);
    }
    // 根据部门查询人员信息
    getbyorg(data, cb) {
        var param = data;
        let url = `Business/SelectPersonByFa`;
        this.post(url, param, cb);
    }
    // 人员部门查询
    GeneralTeacherQueryDepartmentList(data, cb) {
        var param = data;
        let url = `Business/GeneralTeacherQueryDepartmentList`;
        this.post(url, param, cb);
    }
    // 人员查询科目
    ViewSubjectsBasedTeacherIdentity(data, cb) {
        var param = data;
        let url = `Business/ViewSubjectsBasedTeacherIdentity`;
        this.post(url, param, cb);
    }

    // 职务页面
    // 职务查询
    getbyorgid(data, cb) {
        var param = data;
        let url = `Query/query_org_position_byorgid`;
        this.post(url, param, cb);
    }
    // 职务新增
    addposition(data, cb) {
        var param = data;
        let url = `DBObject/new/org_position`;
        this.post(url, param, cb);
    }
    // 职务修改
    updateposition(data, cb) {
        var param = data;
        let url = `DBObject/update/org_position`;
        this.post(url, param, cb);
    }
    // 职务权限
    GetRolePermissions(data, cb) {
        var param = data;
        let url = `Business/GetRolePermissions`;
        this.post(url, param, cb);
    }
    // 职务权限设置
    UpdateRolePermissions(data, cb) {
        var param = data;
        let url = `Business/UpdateRolePermissions`;
        this.post(url, param, cb);
    }
    // 获取数据字典
    dictionary(data, cb) {
        var param = data;
        let url = `Query/query_sys_dictionary`;
        this.post(url, param, cb);
    }

    // 教师页面
    // 获取教师人员
    getTeacherByorg(data, cb) {
        var param = data;
        let url = `Business/QueryTeacherAccordingByOrgTree`;
        this.post(url, param, cb);
    }
    // 添加教师
    // 年级获取
    getQueryGradeByschool(data, cb) {
        var param = data;
        let url = `Query/query_grade_byschool`;
        this.post(url, param, cb);
    }
    // 根据年级id获取班级
    getQueryClassBygrade(data, cb) {
        var param = data;
        let url = `Query/query_class_bygrade`;
        this.post(url, param, cb);
    }
    // 添加教师
    AddTeacherInfo(data, cb) {
        var param = data;
        let url = `Business/AddTeacherInfo`;
        this.post(url, param, cb);
    }
    // 查询教师当前课程
    relationYes(data, cb) {
        var param = data;
        let url = `Query/query_edu_class_teacher_relation_yes`;
        this.post(url, param, cb);
    }
    // 查询教师历史授课记录
    relationNo(data, cb) {
        var param = data;
        let url = `Query/query_edu_class_teacher_relation_no`;
        this.post(url, param, cb);
    }
    // 教师编辑
    UpdateTeacherInfo(data, cb) {
        var param = data;
        let url = `Business/UpdateTeacherInfo`;
        this.post(url, param, cb);
    }
    // 根据病症类型查询病症
    bydiseasetype(data, cb) {
        var param = data;
        let url = `Query/query_cdc_disease_bank_bydiseasetype`;
        this.post(url, param, cb);
    }
    // 学生页面
    // 获取学生
    getStudentByclass(data, cb) {
        var param = data;
        let url = `Query/query_edu_student_list_by_orgtree`;
        this.post(url, param, cb);
    }

    // 获取区域列表
    QueryRegionWithSchool(data, cb) {
        var param = data;
        let url = `Business/QueryGradeWithClass`;
        this.post(url, param, cb);
    }
    // 健康数据页面  今日数据列表
    QueryEduDailyCase(data, cb) {
        var param = data;
        let url = `Query/query_edu_daily_case`;
        this.post(url, param, cb);
    }
    // 健康数据页面   病例列表
    GetTodayStudentCaseBySchool(data, cb) {
        var param = data;
        let url = `Business/GetTodayStudentCaseBySchool`;
        this.post(url, param, cb);
    }
    // 健康数据页面   累计列表
    getQuerySchoolCase(data, cb) {
        var param = data;
        let url = `Business/QueryGradeCase`;
        this.post(url, param, cb);
    }

    // 预警
    // 查询未处理的由疾控下发的预警信息
    QueryWarningReleaseRecordForschool(data, cb) {
        var param = data;
        let url = `Query/query_warning_release_record_forschool`;
        this.post(url, param, cb);
    }
    // 标记预警信息为已阅状态
    Readspan(data, cb) {
        var param = data;
        let url = `DBObject/new/cdc_warning_release_read_record`;
        this.post(url, param, cb);
    }
    // 查询所有由疾控下发的预警信息
    QueryWarningReleaseRecordListForschool(data, cb) {
        var param = data;
        let url = `Business/AcademicAffairsQueryAlertList`;
        this.post(url, param, cb);
    }

    // 综合查询
    // 病症页面  病症类
    // 区域查询下拉
    Query(data, cb) {
        var param = data;
        let url = `Query/query_cdc_sex_query`;
        this.post(url, param, cb);
    }
    // 学校查询下拉
    QueryCdcSexSchoolQuery(data, cb) {
        var param = data;
        let url = `Query/query_cdc_sex_school_query`;
        this.post(url, param, cb);
    }
    // 教育阶段查询下拉
    QueryCdcSexSchoolEdustageQuery(data, cb) {
        var param = data;
        let url = `Query/query_cdc_sex_school_edustage_query`;
        this.post(url, param, cb);
    }
    // 年级查询下拉
    QueryCdcSexSchoolEdustageGrade(data, cb) {
        console.log(data, 'data');
        var param = data;
        let url = `Query/query_cdc_sex_school_edustage_grade`;
        this.post(url, param, cb);
    }
    // 病症下拉
    DiseaseTreeData(data, cb) {
        var param = data;
        let url = `Business/DiseaseTreeDataCopy`;
        this.post(url, param, cb);
    }
    // 按月获取,按条件获取病症列表
    ComprehensiveInquiryDiseaseCategory(data, cb) {
        var param = data;
        let url = `Business/ComprehensiveInquiryDiseaseCategory`;
        this.post(url, param, cb);
    }
    // 教务端 查询传染病患者详细信息
    DiseaseControlQueryPatientDetails(data, cb) {
        var param = data;
        let url = `Business/DiseaseControlQueryPatientDetails`;
        this.post(url, param, cb);
    }
    // 考勤类
    AttendanceComprehensiveQuery(data, cb) {
        var param = data;
        let url = `Business/AttendanceComprehensiveQuery`;
        this.post(url, param, cb);
    }

    // 校医 今日 查询部门列表
    GeneralQueryDepartmentList(data, cb) {
        var param = data;
        let url = `Business/GeneralQueryDepartmentList`;
        this.post(url, param, cb);
    }
    // 校医 今日 根据班级查询病假学生
    QueryCdcCaseLeavecountstudentsByClass(data, cb) {
        var param = data;
        let url = `Query/query_cdc_case_leave_count_students_by_class`;
        this.post(url, param, cb);
    }
    // 校医 今日 查询病症类型
    bydicgroup(data, cb) {
        var param = data;
        let url = `Query/query_sys_dictionary_bydicgroup`;
        this.post(url, param, cb);
    }
    bydicgroup2(data, cb) {
        var param = data;
        let url = `Business/SelectDiseaseType`;
        this.post(url, param, cb);
    }
    // 校医 今日 病症信息标注
    personnelCase(data, cb) {
        var param = data;
        let url = `DBObject/new/cdc_sharding_personnel_case`;
        this.post(url, param, cb);
    }
    // 校医 已标注  查询校医标注的病症学生列表
    QueryPatientsByDiseaseType(data, cb) {
        var param = data;
        let url = `Query/query_patients_by_disease_type`;
        this.post(url, param, cb);
    }
    // 校医 已标注   查询校医标注的病症学生信息-按病症大类
    QueryStudentsIllnessesMarkedSchoolDoctorsClass(data, cb) {
        var param = data;
        let url = `Business/QueryStudentsIllnessesMarkedSchoolDoctorsClass`;
        this.post(url, param, cb);
    }
    // 校医 心理辅导   辅导申请
    AcademicAffairsSchoolDoctorQueryCounseApplication(data, cb) {
        var param = data;
        let url = `Business/AcademicAffairsSchoolDoctorQueryCounseApplication`;
        this.post(url, param, cb);
    }
    // 校医 心理辅导  辅导申请确认
    EduSchoolDoctorPsychological(data, cb) {
        var param = data;
        let url = `DBObject/update/edu_school_doctor_psychological`;
        this.post(url, param, cb);
    }
    // 校医  心理辅导  辅导安排
    QueryEduEndMedicalInquiryPsychologicalCounselingArrange(data, cb) {
        var param = data;
        let url = `Query/query_edu_EndMedical_Inquiry_Psychological_Counseling_Arrange`;
        this.post(url, param, cb);
    }
    // 校医  心理辅导  辅导安排消除心理
    WithdrawalPsychologicalAnnotationTutoringCompleted(data, cb) {
        var param = data;
        let url = `Business/WithdrawalPsychologicalAnnotationTutoringCompleted`;
        this.post(url, param, cb);
    }
    // 校医  心理辅导  辅导记录
    QueryEduEndMedicalInquiryPsychologicalCounselingRecord(data, cb) {
        var param = data;
        let url = `Query/query_edu_EndMedical_Inquiry_Psychological_Counseling_Record`;
        this.post(url, param, cb);
    }

    // 我的页面 课程设置  删除导入文件
    // 我的页面 课程设置  

    // 我的页面 课程设置  行课安排 获取调换课程
    AcademicAffairsTerminalCourseArrangement(data, cb) {
        var param = data;
        let url = `Business/AcademicAffairsTerminalCourseArrangement`;
        this.post(url, param, cb);
    }
    // 我的页面  数据导入  兴趣班读取
    ImporInterestClasstexcel(data, cb) {
        var param = data;
        let url = `Import/ImporInterestClasstexcel?picPath=` + param.filePath;
        this.post(url, param, cb);
    }
    // 我的页面  数据导入  兴趣班课表
    ImporInterestClassHourtexcel(data, cb) {
        var param = data;
        let url = `Import/ImporInterestClassHourtexcel?picPath=` + param.filePath;
        this.post(url, param, cb);
    }
    // 我的页面  数据导入  兴趣班学生
    ImporInterestClassStudentexcel(data, cb) {
        var param = data;
        let url = `Import/ImporInterestClassStudentexcel?picPath=` + param.filePath;
        this.post(url, param, cb);
    }


    // 调课 
    // 教师换课 查询全部代换课列表
    QueryEduReplacementCoursesSchool(data, cb) {
        var param = data;
        let url = `Business/QueryReplacementCourseApplication`;
        this.post(url, param, cb);
    }
    // 教师换课 教务查询可选资源
    OptionalResourcesAcademicInquiry(data, cb) {
        var param = data;
        let url = `Business/OptionalResourcesAcademicInquiry`;
        this.post(url, param, cb);
    }
    // 教师换课 代换课确认
    EduClassHourChangeapplication(data, cb) {
        var param = data;
        let url = `Business/AcademicAffairsEndReviewReplacementCourses`;
        this.post(url, param, cb);
    }
    // 教师换课 调课记录  通过
    QueryCoursesThatHaveBeenReplaced(data, cb) {
        var param = data;
        let url = `query/query_courses_that_have_been_replaced/${data.pageSize}/${data.pageIndex}`;
        this.post(url, param, cb);
    }
    // 教师换课 调课记录  不通过
    QueryCoursesThatFailedBeenReplaced(data, cb) {
        var param = data;
        let url = `query/query_courses_that_failed_been_replaced/${data.pageSize}/${data.pageIndex}`;
        this.post(url, param, cb);
    }
    // 校医 待处置  教务校医查询全部请假
    SchoolDoctorsCheckAllLeaveRequests(data, cb) {
        var param = data;
        let url = `Business/SchoolDoctorsCheckAllLeaveRequests`;
        this.post(url, param, cb);
    }
    // 校医 待处置 按名称模糊查询全部病症
    QueryCdcDiseaseBankNewByname(data, cb) {
        var param = data;
        let url = `Query/query_cdc_disease_bank_new_byname`;
        this.post(url, param, cb);
    }
    // 校医 待处置 解除
    EduLeaveRequest(data, cb) {
        var param = data;
        let url = `DBObject/update/edu_leave_request`;
        this.post(url, param, cb);
    }
    // 校医 待处置  上报
    SchoolDoctorReportsSymptomsBatchesDiseaseCenter(data, cb) {
        var param = data;
        let url = `Business/SchoolDoctorReportsSymptomsBatchesDiseaseCenter`;
        this.post(url, param, cb);
    }
    // 校医 待处置  处置
    SchoolDoctorsHandlingLeaveRequests(data, cb) {
        var param = data;
        let url = `Business/SchoolDoctorsHandlingLeaveRequests`;
        this.post(url, param, cb);
    }
    // 校医 待处置

    // 兴趣班管理页面  获取兴趣班列表
    AcademicAffairsQueryAllInterestClassList(data, cb) {
        var param = data;
        let url = `Business/AcademicAffairsQueryAllInterestClassList`;
        this.post(url, param, cb);
    }
    // 兴趣班管理页面 修改
    EduIntertestClassinf(data, cb) {
        var param = data;
        let url = `DBObject/update/edu_intertest_classinf`;
        this.post(url, param, cb);
    }
    // 兴趣班管理页面  停用
    DiscontinuationInterestClasses(data, cb) {
        var param = data;
        let url = `Business/DiscontinuationInterestClasses`;
        this.post(url, param, cb);
    }

    // 升学
    SearchForAdmissionClasses(data, cb) {
        var param = data;
        let url = `Business/SearchForAdmissionClasses`;
        this.post(url, param, cb);
    }
    // 升学保存
    AcademicAffairsEndStudentEnrollmentPreservation(data, cb) {
        var param = data;
        let url = `Business/AcademicAffairsEndStudentEnrollmentPreservation`;
        this.post(url, param, cb);
    }
    // 班级调整  根据教育阶段，年级获取班级
    QueryClassDetailsBasedUpLevelOrg(data, cb) {
        var param = data;
        let url = `Business/QueryClassDetailsBasedUpLevelOrg`;
        this.post(url, param, cb);
    }
    // 班级调整 未分配学员查询
    UnallocatedStudentInquiry(data, cb) {
        var param = data;
        let url = `Business/UnallocatedStudentInquiry`;
        this.post(url, param, cb);
    }
    // 班级调整 学生移除
    SysPersonnel(data, cb) {
        var param = data;
        let url = `DBObject/update/sys_personnel`;
        this.post(url, param, cb);
    }
    // 班级调整 学生保存到班级
    ClassAdjustmentAndsaving(data, cb) {
        var param = data;
        let url = `Business/ClassAdjustmentAndsaving`;
        this.post(url, param, cb);
    }

    // 毕业生管理  毕业生查询
    QueryEduStudentGraduateInf(data, cb) {
        var param = data;
        let url = `Query/query_edu_student_graduate_inf`;
        this.post(url, param, cb);
    }

    // 课程设置
    // 区域查询下拉
    Query(data, cb) {
        console.log(data, 'data');
        var param = data;
        let url = `Query/query_cdc_sex_query`;
        this.post(url, param, cb);
    }
    // 学校设置 学校信息更改
    UpdateOrginfo(data, cb) {
        var param = data;
        let url = `DBObject/update/org_info`;
        this.post(url, param, cb);
    }
    // 学校设置 学校信息年级设置
    NewlyAddedGradeLevelsSchoolSettings(data, cb) {
        var param = data;
        let url = `Business/NewlyAddedGradeLevelsSchoolSettings`;
        this.post(url, param, cb);
    }
    // 学校设置 学校信息年级查询
    SchoolSettingsQuery(data, cb) {
        var param = data;
        let url = `Business/SchoolSettingsQuery`;
        this.post(url, param, cb);
    }
    // 班级设置 班级保存
    ClassSettingNewClass(data, cb) {
        var param = data;
        let url = `Business/ClassSettingNewClass`;
        this.post(url, param, cb);
    }

    // 消息页面
    // 消息通知获取
    MessageAcceptFromSchool(data, cb) {
        var param = data;
        let url = `Business/MessageAcceptFromSchool`;
        this.post(url, param, cb);
    }
    // 消息 组织机构
    NotificationScope(data, cb) {
        var param = data;
        let url = `Business/NotificationScope`;
        this.post(url, param, cb);
    }
}
const omaha_core_api = new omaha_api();
export default omaha_core_api;
