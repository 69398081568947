<template>
    <div>
        <!-- 日期切换 和点击  切换 -->
        <div class="switchdate">
            <div class="switchdate-date">
                <div>
                    <el-form
                        ref="form"
                        :model="form2"
                        label-width="0px"
                        style="
                            display: flex;
                            box-sizing: border-box;
                            padding-top: 29px;
                            height: 70px;
                        "
                    >
                        <el-form-item style="width: 100%">
                            <el-select
                                v-model="form2.task"
                                placeholder="请选择"
                                style="width: 80px; margin: 0px 0px 0px 20px"
                                @change="changeselect"
                            >
                                <el-option
                                    v-for="(item, index) in task2"
                                    :key="index"
                                    :value="item.month"
                                    :label="item.task"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </div>
                <!-- 日期 -->
                <div class="switchdate-display">
                    <div class="week">
                        <span
                            v-for="(item, index) in dataList"
                            :key="index"
                            :class="item.week == '日' ? 'weekspan2' : ''"
                            >{{ item.week }}</span
                        >
                    </div>
                    <div class="day">
                        <div
                            class="day-div"
                            v-for="(item, index) in dataList"
                            :key="index"
                            :class="
                                item.day == dateitem && yearmonth == item.date2
                                    ? 'dayclick'
                                    : '' ||
                                      (index == 222 && yearmonth == item.date2)
                                    ? 'pasttimes'
                                    : ''
                            "
                            @click="dateclick(item)"
                        >
                            <span>{{ item.day }}</span>
                            <!-- <span v-show="index / 2 != 1"></span> -->
                            <!-- 日期下 -->
                            <div v-if="false">
                                <div
                                    :class="
                                        index == 21
                                            ? 'triangle triangletimes'
                                            : 'triangle' &&
                                              index <= 20 &&
                                              index >= 18
                                            ? 'triangle triangletimes2'
                                            : 'triangle'
                                    "
                                ></div>
                                <div
                                    :class="
                                        index == 21
                                            ? 'cishu cishutimes'
                                            : 'cishu' &&
                                              index <= 20 &&
                                              index >= 18
                                            ? 'cishu cishutimes2'
                                            : 'cishu'
                                    "
                                >
                                    1
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 搜索  暂未使用 -->
            <div class="search" v-if="false">
                <div>
                    <input
                        type="text"
                        class="input"
                        placeholder="学生班级、姓名"
                    />
                    <img
                        class="img"
                        src="../../assets/b7ad2024ae0d1bfd09b0ce3a08fc8d8.png"
                        alt=""
                    />
                </div>
                <div>
                    <div class="span">补打考勤</div>
                    <div class="span2">发布学生状态</div>
                </div>
            </div>
        </div>

        <div class="bottom">
            <div class="bottom-left">
                <LeiftTree :STATGEIDclick="STATGEIDclick"></LeiftTree>
            </div>
            <div class="bottom-right">
                <!-- 晨检统计 -->
                <div class="statistics">
                    <!-- 应到，实到 -->
                    <div class="statistics-left">
                        <div>
                            <span style="border-left: 4px solid #989898"
                                >应到</span
                            >
                            <span>{{ DataList.STUCOUNT }}</span>
                        </div>
                        <div>
                            <span style="border-left: 4px solid #989898"
                                >实到</span
                            >
                            <span>{{ DataList.ACTCOUNT2 }}</span>
                        </div>
                        <div
                            :class="Name == '晨检未到校' ? 'border' : ''"
                            @click="
                                diseaseswitch(
                                    DataList.NOTANSCHOOL2,
                                    '晨检未到校'
                                )
                            "
                            v-if="false"
                        >
                            <span style="border-left: 4px solid #333333"
                                >未到校</span
                            >
                            <span style="color: #333333; font-size: 80px">{{
                                DataList.NOTSCHOOL2
                            }}</span>
                        </div>
                        <div
                            :class="Name == '晨检事假' ? 'border' : ''"
                            @click="diseaseswitch(DataList.THIING2, '晨检事假')"
                        >
                            <span style="border-left: 4px solid #99a9f1"
                                >事假</span
                            >
                            <span style="color: #99a9f1">{{
                                DataList.THINGSCOUNT2
                            }}</span>
                        </div>
                        <div
                            :class="Name == '晨检病假' ? 'border' : ''"
                            @click="diseaseswitch(DataList.CASE2, '晨检病假')"
                        >
                            <span style="border-left: 4px solid #f57272"
                                >病假</span
                            >
                            <span style="color: #f57272">{{
                                DataList.CASECOUNT2
                            }}</span>
                        </div>
                        <div class="heshuan" v-if="false">
                            <span style="border-left: 4px solid #f57272"
                                >核酸</span
                            >
                            <span>60</span>
                            <span>2</span>
                        </div>
                        <span class="cheng">晨</span>
                    </div>
                    <!-- 未到 ，迟到，不适，离校 -->
                    <div class="statistics-right">
                        <div
                            :class="Name == '晨检未到班' ? 'border' : ''"
                            @click="
                                diseaseswitch(
                                    DataList.NOTATCLASS2,
                                    '晨检未到班'
                                )
                            "
                        >
                            <span style="border-left: 4px solid #333333"
                                >未到班</span
                            >
                            <span style="color: #333333; font-size: 80px">{{
                                DataList.NOTCLASS2
                            }}</span>
                        </div>
                        <div
                            :class="Name == '晨检迟到' ? 'border' : ''"
                            @click="
                                diseaseswitch(DataList.LATEDATA2, '晨检迟到')
                            "
                        >
                            <span style="border-left: 4px solid #989898"
                                >迟到</span
                            >
                            <span style="color: #989898">{{
                                DataList.LATECOUNT2
                            }}</span>
                        </div>
                        <div
                            :class="Name == '晨检不适' ? 'border' : ''"
                            @click="
                                diseaseswitch(
                                    DataList.DISCOMFORTDATA2,
                                    '晨检不适'
                                )
                            "
                        >
                            <span style="border-left: 4px solid #333333"
                                >不适</span
                            >
                            <span style="color: #333333">{{
                                DataList.DISCOMFORT2
                            }}</span>
                        </div>
                        <div
                            :class="Name == '晨检离校' ? 'border' : ''"
                            @click="
                                diseaseswitch(DataList.LEAVESCHOOL2, '晨检离校')
                            "
                        >
                            <span style="border-left: 4px solid #333333"
                                >离校</span
                            >
                            <span style="color: #333333">{{
                                DataList.LEAVESCH2
                            }}</span>
                        </div>
                        <div v-if="false">
                            <span style="border-left: 4px solid #cbcbcb"
                                >疑似</span
                            >
                            <span style="color: #cbcbcb">10</span>
                        </div>
                        <div v-if="false">
                            <span style="border-left: 4px solid #f88e57"
                                >缺勤</span
                            >
                            <span style="color: #f88e57">1450</span>
                        </div>
                        <div v-if="false">
                            <span style="border-left: 4px solid #f3777e"
                                >温度异常</span
                            >
                            <span style="color: #f3777e; font-size: 80px"
                                >14</span
                            >
                        </div>
                    </div>
                </div>
                <!-- 动态统计 -->
                <div class="statistics">
                    <!-- 应到，实到 -->
                    <div class="statistics-left">
                        <div>
                            <span style="border-left: 4px solid #989898"
                                >应到</span
                            >
                            <span>{{ DataList.STUCOUNT }}</span>
                        </div>
                        <div>
                            <span style="border-left: 4px solid #989898"
                                >实到</span
                            >
                            <span>{{ DataList.ACTCOUNT }}</span>
                        </div>
                        <!-- <div
                            :class="Name == '动态未到校' ? 'border' : ''"
                            @click="
                                diseaseswitch(
                                    DataList.NOTANSCHOOL,
                                    '动态未到校'
                                )
                            "
                        >
                            <span style="border-left: 4px solid #333333"
                                >未到校</span
                            >
                            <span style="color: #333333; font-size: 80px">{{
                                DataList.NOTSCHOOL
                            }}</span>
                        </div> -->
                        <div
                            :class="Name == '动态事假' ? 'border' : ''"
                            @click="diseaseswitch(DataList.THIING, '动态事假')"
                        >
                            <span style="border-left: 4px solid #99a9f1"
                                >事假</span
                            >
                            <span style="color: #99a9f1">{{
                                DataList.THINGSCOUNT
                            }}</span>
                        </div>
                        <div
                            :class="Name == '动态病假' ? 'border' : ''"
                            @click="diseaseswitch(DataList.CASE, '动态病假')"
                        >
                            <span style="border-left: 4px solid #f57272"
                                >病假</span
                            >
                            <span style="color: #f57272">{{
                                DataList.CASECOUNT
                            }}</span>
                        </div>
                        <div class="heshuan" v-if="false">
                            <span style="border-left: 4px solid #f57272"
                                >核酸</span
                            >
                            <span>60</span>
                            <span>2</span>
                        </div>
                        <span class="cheng">动</span>
                    </div>
                    <!-- 未到 ，迟到，不适，离校 -->
                    <div class="statistics-right">
                        <div
                            :class="Name == '动态未到班' ? 'border' : ''"
                            @click="
                                diseaseswitch(DataList.NOTATCLASS, '动态未到班')
                            "
                        >
                            <span style="border-left: 4px solid #333333"
                                >未到班</span
                            >
                            <span style="color: #333333; font-size: 80px">{{
                                DataList.NOTCLASS
                            }}</span>
                        </div>
                        <div
                            :class="Name == '动态迟到' ? 'border' : ''"
                            @click="
                                diseaseswitch(DataList.LATEDATA, '动态迟到')
                            "
                        >
                            <span style="border-left: 4px solid #989898"
                                >迟到</span
                            >
                            <span style="color: #989898">{{
                                DataList.LATECOUNT
                            }}</span>
                        </div>
                        <div
                            :class="Name == '动态不适' ? 'border' : ''"
                            @click="
                                diseaseswitch(
                                    DataList.DISCOMFORTDATA,
                                    '动态不适'
                                )
                            "
                        >
                            <span style="border-left: 4px solid #333333"
                                >不适</span
                            >
                            <span style="color: #333333">{{
                                DataList.DISCOMFORT
                            }}</span>
                        </div>
                        <div
                            :class="Name == '动态离校' ? 'border' : ''"
                            @click="
                                diseaseswitch(DataList.LEAVESCHOOL, '动态离校')
                            "
                        >
                            <span style="border-left: 4px solid #333333"
                                >离校</span
                            >
                            <span style="color: #333333">{{
                                DataList.LEAVESCH
                            }}</span>
                        </div>
                        <div v-if="false">
                            <span style="border-left: 4px solid #cbcbcb"
                                >疑似</span
                            >
                            <span style="color: #cbcbcb">10</span>
                        </div>
                        <div v-if="false">
                            <span style="border-left: 4px solid #f88e57"
                                >缺勤</span
                            >
                            <span style="color: #f88e57">1450</span>
                        </div>
                        <div v-if="false">
                            <span style="border-left: 4px solid #f3777e"
                                >温度异常</span
                            >
                            <span style="color: #f3777e; font-size: 80px"
                                >14</span
                            >
                        </div>
                    </div>
                </div>

                <!-- 学生信息列表 -->
                <div class="list">
                    <div class="list-top">
                        <!-- left -->
                        <div class="list-topdiv">
                            <span class="title">{{ Name2 }}</span>
                            <!-- table -->
                            <div class="list-top-table">
                                <el-table
                                    ref="multipleTable"
                                    :data="tableData"
                                    tooltip-effect="dark"
                                    style="width: 100%"
                                    @selection-change="handleSelectionChange"
                                    :header-cell-style="{
                                        background: '#F4F4F4',
                                    }"
                                    border
                                    min-height="440"
                                    height="42vh"
                                    @row-click="tableclick"
                                >
                                    <el-table-column
                                        label="姓名"
                                        min-width="18%"
                                    >
                                        <template slot-scope="scope">{{
                                            scope.row.P_NAME
                                        }}</template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="name"
                                        label="班级"
                                        min-width="20%"
                                    >
                                        <template slot-scope="scope"
                                            >{{ scope.row.gradename
                                            }}{{
                                                scope.row.classname
                                            }}</template
                                        >
                                    </el-table-column>
                                    <el-table-column
                                        prop="address"
                                        label="考勤状态"
                                        min-width="20%"
                                    >
                                        <template>
                                            <span
                                                :class="
                                                    this.Name == '动态病假' ||
                                                    this.Name == '晨检病假'
                                                        ? 'bingjia'
                                                        : '' ||
                                                          this.Name ==
                                                              '动态事假' ||
                                                          this.Name ==
                                                              '晨检事假'
                                                        ? 'shijia'
                                                        : ''
                                                "
                                                >{{ Name2 }}</span
                                            >
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="address1"
                                        label="健康考勤"
                                        min-width="20%"
                                        v-if="false"
                                    >
                                        <template slot-scope="scope">
                                            <span style="color: #21af73">{{
                                                scope.row.address1
                                            }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="C_NAME"
                                        label="请假申请人"
                                        min-width="20%"
                                        v-if="
                                            this.Name == '动态病假' ||
                                            this.Name == '晨检病假' ||
                                            this.Name == '动态事假' ||
                                            this.Name == '晨检事假'
                                        "
                                    >
                                        <template slot-scope="scope">
                                            <span
                                                v-if="
                                                    scope.row.P_NAME ==
                                                    scope.row.C_NAME
                                                "
                                                >{{ scope.row.C_NAME }}家长
                                            </span>
                                            <span
                                                v-if="
                                                    scope.row.P_NAME !=
                                                    scope.row.C_NAME
                                                "
                                                >{{ scope.row.C_NAME }}老师
                                            </span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="address"
                                        label="请假时间"
                                        min-width="20%"
                                        v-if="
                                            this.Name == '动态病假' ||
                                            this.Name == '晨检病假' ||
                                            this.Name == '动态事假' ||
                                            this.Name == '晨检事假'
                                        "
                                    >
                                        <template slot-scope="scope">
                                            <span>{{
                                                $date.DatetimestampTimeMDhm(
                                                    scope.row.CTIME
                                                )
                                            }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="PHONE"
                                        label="请假周期"
                                        min-width="30%"
                                        v-if="
                                            this.Name == '动态病假' ||
                                            this.Name == '晨检病假' ||
                                            this.Name == '动态事假' ||
                                            this.Name == '晨检事假'
                                        "
                                    >
                                        <template slot-scope="scope">
                                            <span>{{
                                                $date.DatetimestampTimeMDhm(
                                                    scope.row.BTIME
                                                ) +
                                                "--" +
                                                $date.DatetimestampTimeMDhm(
                                                    scope.row.ETIME
                                                )
                                            }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="PHONE"
                                        label="家长电话"
                                        min-width="30%"
                                    >
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <!-- right -->
                        <div
                            class="list-topdiv"
                            style="margin-left: 10px"
                            v-if="false"
                        >
                            <span class="title">今日未到班</span>
                            <!-- table -->
                            <div class="list-top-table">
                                <el-table
                                    ref="multipleTable"
                                    :data="tableData2"
                                    tooltip-effect="dark"
                                    style="width: 100%"
                                    @selection-change="handleSelectionChange"
                                    :header-cell-style="{
                                        background: '#F4F4F4',
                                    }"
                                    border
                                    min-height="440"
                                    height="42vh"
                                    @row-click="tableclick"
                                >
                                    <el-table-column
                                        type="selection"
                                        show-overflow-tooltip
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        label="姓名"
                                        min-width="18%"
                                    >
                                        <template slot-scope="scope">{{
                                            scope.row.date
                                        }}</template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="name"
                                        label="班级"
                                        min-width="22%"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        label="温度℃"
                                        min-width="15%"
                                    >
                                        <template slot-scope="scope">
                                            <span style="color: #f3777e">{{
                                                scope.row.address
                                            }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        label="考勤状态"
                                        min-width="18%"
                                    >
                                        <template slot-scope="scope">
                                            <span style="color: #21af73">{{
                                                scope.row.address1
                                            }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="address3"
                                        label="健康考勤"
                                        min-width="18%"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        prop="address2"
                                        label="家长电话"
                                        min-width="26%"
                                    >
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </div>
                    <!-- 学生管控 -->
                    <div class="list-bottom">
                        <div>
                            <span class="span" v-if="false">管 控</span>
                            <span
                                class="span"
                                v-if="false"
                                style="margin-left: 35px"
                                >解 除</span
                            >
                        </div>
                        <div v-if="false">
                            <span class="span">通知家长</span>
                            <span class="span2">通知班主任</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 学生信息 弹出框 -->
            <div>
                <el-dialog
                    :visible.sync="dialogVisible"
                    width="50%"
                    :before-close="handleClose"
                >
                    <!-- 头像 -->
                    <div class="portrait">
                        <div class="portrait-div">
                            <div>
                                <img
                                    class="name-img"
                                    v-if="Student.FILE_ID"
                                    :src="
                                        'http://47.93.213.107:5000/api/file/get/' +
                                        Student.FILE_ID
                                    "
                                    alt=""
                                />
                                <img
                                    class="name-img"
                                    v-if="
                                        (Student.FILE_ID == null ||
                                            Student.FILE_ID == '') &&
                                        Student.SEX == 2
                                    "
                                    src="https://s1.ax1x.com/2022/11/22/z1ZJtH.png"
                                    alt=""
                                />
                                <img
                                    class="name-img"
                                    v-if="
                                        (Student.FILE_ID == null ||
                                            Student.FILE_ID == '') &&
                                        Student.SEX == 1
                                    "
                                    src="https://s1.ax1x.com/2022/12/08/z2YeTf.png"
                                    alt=""
                                />
                                <img
                                    class="name-img"
                                    v-if="
                                        (Student.FILE_ID == null ||
                                            Student.FILE_ID == '') &&
                                        Student.SEX == 0
                                    "
                                    src="https://s1.ax1x.com/2023/01/09/pSeuZl9.png"
                                    alt=""
                                />
                            </div>
                            <span class="portrait-name">{{
                                Student.P_NAME
                            }}</span>
                            <span class="portrait-class"
                                >{{ Student.gradename
                                }}{{ Student.classname }}</span
                            >
                        </div>
                    </div>
                    <!-- 学生信息 -->
                    <div class="information">
                        <div>
                            <span style="width: 120px">身份证号</span
                            ><span style="width: 220px">{{
                                Student.IDCARD
                            }}</span>
                            <span style="width: 84px">性别</span
                            ><span style="width: 84px" v-if="Student.SEX == 0"
                                >未知</span
                            >
                            <span style="width: 84px" v-if="Student.SEX == 1"
                                >女</span
                            >
                            <span style="width: 84px" v-if="Student.SEX == 2"
                                >男</span
                            >
                            <span style="width: 84px">学号</span
                            ><span style="width: 136px">暂无</span>
                        </div>
                        <div>
                            <span style="width: 120px">家长电话</span>
                            <span style="width: 220px">{{
                                Student.PHONE
                            }}</span>
                            <span style="width: 168px">&nbsp;</span
                            ><span style="width: 220px">&nbsp;</span>
                        </div>
                    </div>
                    <!-- 事假，核酸图片  暂未使用  -->
                    <div class="nucleicacid" v-if="false">
                        <div>
                            <span>病假</span>
                            <div class="nucleicacid-img">
                                <!-- 病假图片 -->
                                <img src="" alt="" />
                            </div>
                        </div>
                        <div v-if="false">
                            <span>核酸报告</span>
                            <div class="nucleicacid-img">
                                <!-- 核酸报告图片 -->
                                <img src="" alt="" />
                            </div>
                        </div>
                    </div>
                </el-dialog>
            </div>
        </div>
    </div>
</template>

<script>
// 引入
import LeiftTree from "../../components/check/LeiftTree.vue";
export default {
    components: {
        LeiftTree,
    },
    props: {
        //  在props中接收父组件传过来的值
        switchdate: {
            type: Function,
        },
        STATGEIDclick: {
            type: Function,
        },
        DataList: {},
    },
    data() {
        return {
            // 切换月份
            task2: [
                { task: "1月", month: "1" },
                { task: "2月", month: "2" },
                { task: "3月", month: "3" },
                { task: "4月", month: "4" },
                { task: "5月", month: "5" },
                { task: "6月", month: "6" },
                { task: "7月", month: "7" },
                { task: "8月", month: "8" },
                { task: "9月", month: "9" },
                { task: "10月", month: "10" },
                { task: "11月", month: "11" },
                { task: "12月", month: "12" },
            ],
            form2: { task: "" },
            // 获取该月份的天数
            year: "2022",
            month: "10",
            dateday: "",
            day: "",
            // 点击切换月
            yearmonth: "",
            // 点击日期
            dateitem: "",
            dataList: [],
            //   table
            tableData: {},
            Name: "",
            Name2: "",
            tableData2: [],
            multipleSelection: [],
            //   弹出框判断
            dialogVisible: false,
            // 点击学生信息
            Student: {},
        };
    },
    beforeMount() {
        var date = new Date();
        this.year = date.getFullYear() + "";
        this.month = (date.getMonth() + 1 + "").padStart(2, "0");
        this.dateday = (date.getDate() + "").padStart(2, "0");
        this.mGetDate(this.year, this.month);
        // 月份下拉from2
        this.form2.task = date.getMonth() + 1 + "";
        console.log(this.form2, "this.form22222222222222222");
        // 获取当天日期
        this.dateitem = date.getDate();
        // 判断点击月
        this.yearmonth = date.getMonth() + 1 + "";
        // 点击获取日期
        // console.log(
        //     this.year,
        //     this.month,
        //     this.dateday,
        //     "this.yearthis.yearthis.year"
        // );
        this.switchdate(this.year + "-" + this.month + "-" + this.dateday);
        this.monthDays(this.year, this.form2.task);
        // 未到班
        this.tableData = this.DataList.NOTANSCHOOL;
        this.Name = "未到班";
    },
    methods: {
        // 获取该月份的天数
        mGetDate(year, month) {
            var d = new Date(year, month, 0);
            this.day = d.getDate();
        },
        // 点击切换日期
        dateclick(e) {
            this.dateitem = e.day;
            // console.log(this.dateitem, "this.dateitem");
            var dt = e.day + "";

            this.yearmonth = e.date2;
            // console.log(
            //     this.year +
            //         "-" +
            //         this.yearmonth.padStart(2, "0") +
            //         "-" +
            //         dt.padStart(2, "0")
            // );
            this.switchdate(
                this.year +
                    "-" +
                    this.yearmonth.padStart(2, "0") +
                    "-" +
                    dt.padStart(2, "0")
            );
        },
        // 获取日期
        monthDays(year, month) {
            var stratDate = new Date(year, month - 1, 1),
                endData = new Date(year, month, 1);
            var days = (endData - stratDate) / (1000 * 60 * 60 * 24);
            var list = [];
            for (var i = 1; i <= days; i++) {
                const day = i;
                const date = month + "-" + day;
                const date2 = month;
                const week =
                    new Date(month + "/" + i + "/" + year).getDay() == 0
                        ? "日"
                        : new Date(month + "/" + i + "/" + year).getDay() == 1
                        ? "一"
                        : new Date(month + "/" + i + "/" + year).getDay() == 2
                        ? "二"
                        : new Date(month + "/" + i + "/" + year).getDay() == 3
                        ? "三"
                        : new Date(month + "/" + i + "/" + year).getDay() == 4
                        ? "四"
                        : new Date(month + "/" + i + "/" + year).getDay() == 5
                        ? "五"
                        : new Date(month + "/" + i + "/" + year).getDay() == 6
                        ? "六"
                        : "";
                list.push({
                    date,
                    date2,
                    week,
                    day,
                });
            }
            this.dataList = list;
            console.log(this.year, this.month, this.dataList, "dataList");
        },
        // 月份下拉
        changeselect() {
            console.log(this.form2, "this.form2");
            console.log(this.year, this.form2);
            this.monthDays(this.year, this.form2.task);
        },

        toggleSelection(rows) {
            if (rows) {
                rows.forEach((row) => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 弹出框
        tableclick(val) {
            console.log(val, "val");
            this.dialogVisible = true;
            this.Student = val;
        },
        // 关闭弹出框
        handleClose(done) {
            done();
        },
        // 点击病症切换列表
        diseaseswitch(e, name) {
            this.tableData = e;
            this.Name = name;
            this.Name2 = name.substring(2, name.lenght);
        },
    },
    // 监听事件
    watch: {
        // 此处监听DataList变量，当有变化时执行 。
        DataList(item1, item2) {
            this.tableData = this.DataList.THIING;
            this.Name = "动态事假";
            this.Name2 = "事假";
        },
    },
};
</script>

<style scoped lang='scss'>
@import "../../style/_style.scss";
.switchdate {
    width: 100%;
    height: 150px;
    background: $background;
    border-radius: 10px;
}
/* 日期切换 */
.switchdate-date {
    width: 100%;
    height: 90px;
    border-radius: 10px;
    background: $background;
    display: flex;
}
.switchdate-display {
    flex-grow: 1;
    box-sizing: border-box;
    padding: 0px 10px;
}
.week {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}
.week span {
    display: inline-block;
    width: 30px;
    height: 20px;
    text-align: center;
    font-size: 8px;
    color: #b2b2b2;
}
.week .weekspan2 {
    color: #333333;
    font-weight: bold;
}
.day {
    display: flex;
    justify-content: space-around;
}
.day div {
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    position: relative;
    font-weight: bold;
    color: #000000;
    font-size: 14px;
}
.day .dayclick {
    background: $colorlv;
    color: $background;
}
.day div span:nth-child(2) {
    display: inline-block;
    width: 7px;
    height: 7px;
    background: $colorhong;
    border-radius: 50%;
    position: absolute;
    bottom: -3px;
    left: 12px;
}
// bottom
.bottom {
    display: flex;
    .bottom-left {
        width: 300px;
        background: #ffffff;
        border-radius: 10px;
        margin-top: 10px;
        min-height: 922px;
        height: calc(100vh - 258px);
    }
    .bottom-right {
        flex-grow: 1;
        margin-left: 20px;
        margin-right: 10px;
    }
}

/* 搜索 */
.search {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 10px 20px 10px 27px;
}
.search .input {
    width: 185px;
    height: 40px;
    box-sizing: border-box;
    vertical-align: middle;
    border: 1px solid #cbcbcb;
    padding-left: 10px;
    border-radius: 4px;
    outline: none;
}
.search .img {
    width: 26px;
    height: 26px;
    vertical-align: middle;
    margin-left: 20px;
}
.span {
    width: 96px;
    height: 36px;
    line-height: 36px;
    display: inline-block;
    text-align: center;
    color: $colorlv;
    background: $back-button;
    font-size: 14px;
    border-right: 4px;
}
.span2 {
    width: 126px;
    height: 36px;
    line-height: 36px;
    display: inline-block;
    text-align: center;
    color: $colorlv;
    background: $back-button;
    font-size: 14px;
    margin-left: 35px;
    border-right: 4px;
}
// 统计
.statistics {
    width: 100%;
    height: 150px;
    margin-top: 10px;
    display: flex;
}
.statistics-left {
    width: 55%;
    box-sizing: border-box;
    padding: 6px;
    border: 1px solid $colorlv;
    border-radius: 10px;
    display: flex;
    position: relative;
}
.cheng {
    position: absolute;
    top: -10px;
    left: -12px;
    width: 26px;
    height: 26px;
    text-align: center;
    line-height: 20px;
    background: $colorlv;
    color: $background;
    border: 3px solid $background;
    border-radius: 50%;
    box-sizing: border-box;
    font-size: 12px !important;
}
.statistics-right {
    width: 45%;
    margin-left: 10px;
    box-sizing: border-box;
    padding: 6px;
    border: 1px solid $colorlv;
    border-radius: 10px;
    display: flex;
}
.statistics-left div {
    width: 20%;
    max-width: 157.5px;
    height: 135px;
    background: $background;
    border-radius: 10px;
    margin-right: 6px;
    box-sizing: border-box;
    padding: 10px 0px 10px 0px;
    font-size: 15px;
    color: #333333;
    font-weight: bold;
}
.statistics-left div span {
    display: block;
}
.statistics-left div span:nth-child(1) {
    box-sizing: border-box;
    padding-left: 5px;
    margin-left: 17px;
}
.statistics-left div span:nth-child(2) {
    font-size: 33px;
    color: $colorhui;
    height: 96px;
    line-height: 96px;
    text-align: center;
}
.statistics-left div:nth-child(6) {
    margin-right: 0px;
}
// 核酸
.heshuan {
    padding: 10px 17px 10px 17px !important;
}
.heshuan span:nth-child(1) {
    margin: 0px !important;
}
.heshuan span:nth-child(2) {
    width: 100%;
    height: 75px !important;
    line-height: 75px !important;
    border: 1px solid $color;
    border-radius: 10px;
    margin-top: 7px;
}
.heshuan span:nth-child(3) {
    width: 100%;
    border: 1px solid $colorhuang;
    height: 75px !important;
    line-height: 75px !important;
    border-radius: 10px;
    font-size: 34px;
    text-align: center;
    margin-top: 7px;
    color: $colorhuang;
}
// 未到班
.statistics-right div {
    width: 20%;
    height: 135px;
    background: $background;
    border-radius: 10px;
    margin-right: 6px;
    box-sizing: border-box;
    padding: 10px 0px 10px 0px;
    font-size: 15px;
    color: #333333;
    font-weight: bold;
}
.statistics-right div span {
    display: block;
}
.statistics-right div span:nth-child(1) {
    box-sizing: border-box;
    padding-left: 5px;
    margin-left: 17px;
}
.statistics-right div span:nth-child(2) {
    font-size: 33px;
    color: $colorhui;
    height: 96px;
    line-height: 96px;
    text-align: center;
}
.statistics-right div:nth-child(5) {
    margin-right: 0px;
}
// 列表
.list {
    display: inline-block;
    width: calc(100% - 330px);
    min-height: 600px;
    height: calc(100vh - 578px);
    background: $background;
    margin-top: 12px;
    border-radius: 10px;
    position: absolute;
}
.list-top {
    width: 100%;
    height: 520px;
    display: flex;
}
.list-top .list-topdiv {
    width: 100%;
    height: 100%;
}

.title {
    display: inline-block;
    width: 100%;
    height: 60px !important;
    font-size: 14px;
    color: $color;
    font-weight: bold;
    line-height: 60px;
    box-sizing: border-box;
    padding-left: 20px;
}
.list-top-table {
    width: 100%;
    height: 460px;
    padding: 0px 20px;
    box-sizing: border-box;
}
// 按钮
.list-bottom {
    height: 80px;
    line-height: 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
    box-sizing: border-box;
    position: absolute;
    bottom: 0px;
}
// 弹出框
.portrait {
    width: 100%;
    height: 180px;
}
.portrait .portrait-div {
    width: 137px;
    height: 180px;
    margin: 0 auto;
    text-align: center;
}
.portrait .portrait-div div {
    width: 137px;
    height: 137px;
    border-radius: 50%;
    background-image: linear-gradient(#eafcff, #e4ffd9);
    text-align: center;
}
.portrait .portrait-div div img {
    display: inline-block;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    margin-top: 13px;
}
.portrait-name {
    display: block;
    font-size: 20px;
    font-weight: bold;
    color: $color;
}
.portrait-class {
    display: block;
    font-size: 14px;
    color: $color;
}
// 学生信息
.information {
    width: 80%;
    height: 100px;
    margin: 20px auto;
    text-align: center;
}
.information div {
    height: 50px;
    width: 100%;
    font-size: 14px;
    color: #989898;
}
.information div span {
    display: inline-block;
    height: 50px;
    text-align: center;
    line-height: 50px;
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
}
// 病假 核酸报告
.nucleicacid {
    width: 85%;
    height: 250px;
    margin: 0px auto;
    display: flex;
}
.nucleicacid div {
    width: 50%;
}
.nucleicacid div span {
    color: $color;
    font-size: 14px;
    font-weight: bold;
    vertical-align: top;
}
.nucleicacid-img {
    display: inline-block;
    width: 209px;
    height: 250px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    padding: 0px 10px;
    margin-left: 15px;
    vertical-align: top;
}
.nucleicacid .nucleicacid-img img {
    width: 172px;
    height: 244px;
}
// bingjia
.bingjia {
    color: #f3777e;
}
.shijia {
    color: #99a9f1;
}

// 点击边框
.border {
    border: 1px solid #21af73;
    box-sizing: border-box;
}
</style>