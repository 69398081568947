import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import axios from 'axios'


import $ from 'jquery'
Vue.prototype.$ = $;

// 将 api 挂载到全局
import api from './http/axiosapi';
Vue.prototype.$api = api;

// 天气发送
Vue.prototype.$axios = axios

Vue.use(ElementUI);
Vue.config.productionTip = false

//头像上传截图
import VueCropper from 'vue-cropper';
Vue.use(VueCropper);

// 时间戳转换
import date from './components/date/date'
Vue.prototype.$date = date

// 保存全局 数据 （数据字典）
import globle from "./components/globle"
Vue.prototype.$globle = globle;

// table组件
Vue.prototype.$bus = new Vue()

// 添加浏览器title
router.beforeEach((to, from, next) => {
    window.document.title = '学校晨检安全综合管理子系统'
    if (to.meta.requireAuth) {
        let token = Cookies.get('access_token');
        let anonymous = Cookies.get('user_name');
        if (token) {

            next({
                path: '/login',
                query: {
                    redirect: to.fullPath
                }
            })

        }
    }
    next();
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
