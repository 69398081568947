<template>
    <div class="elrow">
        <div class="logo">
            <img src="../assets/fe60335750349667331a8672f5fa647.png" alt="" />
        </div>
        <!-- 导航菜单 -->
        <div style="letter-spacing: 2px" class="elrowlist">
            <el-row class="tac" default-active="1">
                <el-col :span="12">
                    <el-menu
                        :default-active="$route.path"
                        class="el-menu-vertical-demo"
                        text-color="#BFBFBF"
                        active-text-color="#22AF74"
                        :unique-opened="true"
                        @open="handleOpen"
                        @close="handleClose"
                        :router="true"
                    >
                        <!-- 教务首页 -->
                        <el-menu-item index="/homePage" v-if="false">
                            <img
                                class="img"
                                v-show="$route.path == '/homePage'"
                                src="../assets/教务端pc/消息.png"
                                alt=""
                            />
                            <img
                                class="img"
                                v-show="$route.path != '/homePage'"
                                src="../assets/教务端pc/消息-1.png"
                                alt=""
                            />
                            <span slot="title" class="title">教务首页</span>
                        </el-menu-item>
                        <!-- 考勤 -->
                        <el-submenu
                            index="1"
                            v-if="
                                $globle.UserInfoJurisdiction('Check')
                                    ? true
                                    : false
                            "
                        >
                            <template slot="title">
                                <img
                                    class="img"
                                    src="../assets/教务端pc/235考勤、出勤.png"
                                    alt=""
                                />
                                <span class="title">考勤</span>
                            </template>
                            <el-menu-item
                                v-if="
                                    $globle.UserInfoJurisdiction(
                                        'ClassroomCheck'
                                    )
                                        ? true
                                        : false
                                "
                                index="/classroomCheck"
                                class="title"
                                style="padding-left: 58px"
                                >课堂考勤</el-menu-item
                            >
                            <el-menu-item
                                v-if="
                                    $globle.UserInfoJurisdiction(
                                        'InterestclassCheck'
                                    )
                                        ? false
                                        : false
                                "
                                index="/interestCheck"
                                class="title"
                                style="padding-left: 58px"
                                >兴趣班考勤</el-menu-item
                            >
                            <el-menu-item
                                v-if="
                                    $globle.UserInfoJurisdiction('WorkersCheck')
                                        ? false
                                        : false
                                "
                                index="/facultyCheck"
                                class="title"
                                style="padding-left: 58px"
                                >教职工考勤</el-menu-item
                            >
                        </el-submenu>
                        <!-- 健康数据 -->
                        <el-submenu
                            index="3"
                            v-if="
                                $globle.UserInfoJurisdiction('HealthData')
                                    ? true
                                    : false
                            "
                        >
                            <template slot="title">
                                <img
                                    class="img"
                                    src="../assets/教务端pc/蒙版组 782.png"
                                    alt=""
                                />
                                <span class="title">健康数据</span>
                            </template>

                            <el-menu-item
                                index="/healthdata"
                                class="title"
                                style="padding-left: 58px"
                                v-if="
                                    $globle.UserInfoJurisdiction('Today')
                                        ? true
                                        : false
                                "
                                >今日</el-menu-item
                            >
                            <el-menu-item
                                index="/case"
                                class="title"
                                style="padding-left: 58px"
                                v-if="
                                    $globle.UserInfoJurisdiction('Case')
                                        ? true
                                        : false
                                "
                                >病例</el-menu-item
                            >
                            <el-menu-item
                                index="/cumulative"
                                class="title"
                                style="padding-left: 58px"
                                v-if="
                                    $globle.UserInfoJurisdiction('Cumulative')
                                        ? true
                                        : false
                                "
                                >累计</el-menu-item
                            >
                        </el-submenu>
                        <!-- 调课 -->
                        <el-submenu
                            index="2"
                            v-if="
                                $globle.UserInfoJurisdiction('Switchlessons')
                                    ? true
                                    : false
                            "
                        >
                            <template slot="title">
                                <img
                                    class="img"
                                    src="../assets/教务端pc/切换-1.png"
                                    alt=""
                                />
                                <span class="title">调课</span>
                            </template>
                            <el-menu-item
                                v-if="
                                    $globle.UserInfoJurisdiction(
                                        'TeacherSwitchlessons'
                                    )
                                        ? true
                                        : false
                                "
                                index="/teacherTransfer"
                                class="title"
                                style="padding-left: 58px"
                                >教师换课</el-menu-item
                            >
                            <el-menu-item
                                v-if="
                                    $globle.UserInfoJurisdiction(
                                        'StudentSwitchlessons'
                                    )
                                        ? true
                                        : false
                                "
                                index="/studentTransfer"
                                class="title"
                                style="padding-left: 58px"
                                >学生换课</el-menu-item
                            >
                        </el-submenu>
                        <!-- 部门及人员 -->
                        <el-submenu
                            index="6"
                            v-if="
                                $globle.UserInfoJurisdiction(
                                    'DepartmentAndPersonnel'
                                )
                                    ? true
                                    : false
                            "
                        >
                            <template slot="title">
                                <img
                                    class="img"
                                    src="../assets/教务端pc/蒙版组 780.png"
                                    alt=""
                                />
                                <span class="title">部门及人员</span>
                            </template>
                            <el-menu-item
                                v-if="
                                    $globle.UserInfoJurisdiction('Department')
                                        ? false
                                        : false
                                "
                                index="/department"
                                class="title"
                                style="padding-left: 58px"
                                >部门</el-menu-item
                            >
                            <el-menu-item
                                v-if="
                                    $globle.UserInfoJurisdiction('Department')
                                        ? true
                                        : false
                                "
                                index="/department2"
                                class="title"
                                style="padding-left: 58px"
                                >部门</el-menu-item
                            >
                            <el-menu-item
                                v-if="
                                    $globle.UserInfoJurisdiction('Post')
                                        ? true
                                        : false
                                "
                                index="/duty"
                                class="title"
                                style="padding-left: 58px"
                                >职务</el-menu-item
                            >
                            <el-menu-item
                                v-if="
                                    $globle.UserInfoJurisdiction('Personnel')
                                        ? false
                                        : false
                                "
                                index="/personnel"
                                class="title"
                                style="padding-left: 58px"
                                >人员</el-menu-item
                            >
                            <el-menu-item
                                v-if="
                                    $globle.UserInfoJurisdiction('Personnel')
                                        ? true
                                        : false
                                "
                                index="/personnel2"
                                class="title"
                                style="padding-left: 58px"
                                >人员</el-menu-item
                            >
                            <el-menu-item
                                v-if="
                                    $globle.UserInfoJurisdiction('Teacher')
                                        ? false
                                        : false
                                "
                                index="/teacher"
                                class="title"
                                style="padding-left: 58px"
                                >教师</el-menu-item
                            >
                            <el-menu-item
                                v-if="
                                    $globle.UserInfoJurisdiction('Student')
                                        ? true
                                        : false
                                "
                                index="/student"
                                class="title"
                                style="padding-left: 58px"
                                >学生</el-menu-item
                            >
                        </el-submenu>
                        <!-- 消息 -->
                        <el-menu-item
                            index="/news"
                            v-if="
                                $globle.UserInfoJurisdiction('News')
                                    ? true
                                    : false
                            "
                        >
                            <img
                                class="img"
                                v-show="$route.path == '/news'"
                                src="../assets/教务端pc/消息.png"
                                alt=""
                            />
                            <img
                                class="img"
                                v-show="$route.path != '/news'"
                                src="../assets/教务端pc/消息-1.png"
                                alt=""
                            />
                            <span slot="title" class="title">消息</span>
                        </el-menu-item>
                        <!-- 综合查询 -->
                        <el-submenu
                            index="5"
                            v-if="
                                $globle.UserInfoJurisdiction(
                                    'ComprehensiveQuery'
                                )
                                    ? true
                                    : false
                            "
                        >
                            <template slot="title">
                                <img
                                    class="img"
                                    src="../assets/教务端pc/高级查询-1.png"
                                    alt=""
                                />
                                <span class="title">综合查询</span>
                            </template>
                            <el-menu-item
                                v-if="
                                    $globle.UserInfoJurisdiction('CheckQuery')
                                        ? true
                                        : false
                                "
                                index="/attendance"
                                class="title"
                                style="padding-left: 58px"
                                >考勤查询</el-menu-item
                            >
                            <el-menu-item
                                v-if="
                                    $globle.UserInfoJurisdiction('DiseaseClass')
                                        ? true
                                        : false
                                "
                                index="/disease"
                                class="title"
                                style="padding-left: 58px"
                                >病症类</el-menu-item
                            >
                            <el-menu-item
                                v-if="
                                    $globle.UserInfoJurisdiction('GenderClass')
                                        ? false
                                        : false
                                "
                                index="/gender"
                                class="title"
                                style="padding-left: 58px"
                                >性别类</el-menu-item
                            >
                        </el-submenu>
                        <!-- 我的 -->
                        <el-submenu
                            index="13"
                            v-if="
                                $globle.UserInfoJurisdiction('My')
                                    ? true
                                    : false
                            "
                        >
                            <template slot="title">
                                <img
                                    class="img"
                                    src="../assets/教务端pc/资源-2.png"
                                    alt=""
                                />
                                <span class="title">我的</span>
                            </template>
                            <el-menu-item
                                v-if="
                                    $globle.UserInfoJurisdiction(
                                        'TrusteeshipStudentRegister'
                                    )
                                        ? true
                                        : false
                                "
                                index="/studentRegistration"
                                style="padding-left: 58px"
                                class="title"
                                >托管生注册</el-menu-item
                            >
                            <el-menu-item
                                v-if="
                                    $globle.UserInfoJurisdiction(
                                        'SchoolAnnouncement'
                                    )
                                        ? true
                                        : false
                                "
                                index="/notice"
                                class="title"
                                style="padding-left: 58px"
                                >学校公告</el-menu-item
                            >
                            <el-menu-item
                                v-if="
                                    $globle.UserInfoJurisdiction('ReportQuery')
                                        ? true
                                        : false
                                "
                                index="/reportform"
                                class="title"
                                style="padding-left: 58px"
                                >报表查询</el-menu-item
                            >
                            <el-menu-item
                                v-if="
                                    $globle.UserInfoJurisdiction(
                                        'InterestClassManagement'
                                    )
                                        ? true
                                        : false
                                "
                                index="/classTube"
                                class="title"
                                style="padding-left: 58px"
                                >兴趣班管理</el-menu-item
                            >
                            <el-menu-item
                                v-if="
                                    $globle.UserInfoJurisdiction(
                                        'HealthAttendanceManagement'
                                    )
                                        ? true
                                        : false
                                "
                                index="/healthyant"
                                class="title"
                                style="padding-left: 58px"
                                >健康考勤管理</el-menu-item
                            >
                            <el-menu-item
                                v-if="
                                    $globle.UserInfoJurisdiction(
                                        'FunctionSetting'
                                    )
                                        ? true
                                        : false
                                "
                                index="/function"
                                class="title"
                                style="padding-left: 58px"
                                >功能设置</el-menu-item
                            >
                            <el-menu-item
                                v-if="
                                    $globle.UserInfoJurisdiction(
                                        'PaymentManagement'
                                    )
                                        ? true
                                        : false
                                "
                                index="/pay"
                                class="title"
                                style="padding-left: 58px"
                                >缴费管理</el-menu-item
                            >
                            <el-menu-item
                                v-if="
                                    $globle.UserInfoJurisdiction(
                                        'VisitingRecords'
                                    )
                                        ? true
                                        : false
                                "
                                index="/visit"
                                class="title"
                                style="padding-left: 58px"
                                >来访记录</el-menu-item
                            >
                            <el-menu-item
                                v-if="
                                    $globle.UserInfoJurisdiction(
                                        'ClassHonorSetting'
                                    )
                                        ? true
                                        : false
                                "
                                index="/honor"
                                class="title"
                                style="padding-left: 58px"
                                >班级荣誉设置</el-menu-item
                            >
                            <el-menu-item
                                v-if="
                                    $globle.UserInfoJurisdiction(
                                        'CourseSchedulingSettings'
                                    )
                                        ? true
                                        : false
                                "
                                index="/curriculum"
                                class="title"
                                style="padding-left: 58px"
                                >课程设置</el-menu-item
                            >
                        </el-submenu>

                        <!-- 警告排列 -->
                        <el-submenu
                            index="15"
                            v-if="
                                $globle.UserInfoJurisdiction('EarlyWarning')
                                    ? true
                                    : false
                            "
                        >
                            <template slot="title">
                                <img
                                    class="img"
                                    src="../assets/47e258a1eda8ebb925222fdfad4210a.png"
                                    alt=""
                                />
                                <span class="title">警告</span>
                            </template>
                            <el-menu-item
                                index="/warninglist"
                                class="title"
                                style="padding-left: 58px"
                                v-if="
                                    $globle.UserInfoJurisdiction(
                                        'EarlyWarningList'
                                    )
                                        ? true
                                        : false
                                "
                                >警告列表</el-menu-item
                            >
                        </el-submenu>
                        <!-- 系统管理 -->
                        <el-menu-item
                            index="/system"
                            v-if="
                                $globle.UserInfoJurisdiction('SystemManagement')
                                    ? false
                                    : false
                            "
                        >
                            <img
                                style="margin-left: -3px"
                                class="img"
                                v-show="$route.path == '/system'"
                                src="../assets/教务端pc/07ddb96aaa72a53f5c467b0f211081b.png"
                                alt=""
                            />
                            <img
                                style="margin-left: -3px"
                                class="img"
                                v-show="$route.path != '/system'"
                                src="../assets/教务端pc/a2ba43f64a47ca48fcd2108ee379802.png"
                                alt=""
                            />
                            <span slot="title" class="title">系统管理</span>
                        </el-menu-item>
                        <!-- 校医 -->
                        <el-submenu
                            index="xiaoyi"
                            v-if="
                                $globle.UserInfoJurisdiction('SchoolDoctor')
                                    ? true
                                    : false
                            "
                        >
                            <template slot="title">
                                <img
                                    class="img"
                                    src="../assets/教务端pc/ca1d1a3dadefed8eadbca25a9a09a9f.png"
                                    alt=""
                                />
                                <span class="title">校医</span>
                            </template>
                            <el-menu-item
                                v-if="
                                    $globle.UserInfoJurisdiction(
                                        'SchoolDoctorDay'
                                    )
                                        ? true
                                        : false
                                "
                                index="/schoolDoctorDay"
                                class="title"
                                style="padding-left: 58px"
                                >今日</el-menu-item
                            >
                            <el-menu-item
                                v-if="
                                    $globle.UserInfoJurisdiction('Marked')
                                        ? true
                                        : false
                                "
                                index="/marked"
                                class="title"
                                style="padding-left: 58px"
                                >已标注</el-menu-item
                            >
                            <el-menu-item
                                v-if="
                                    $globle.UserInfoJurisdiction('Psychology')
                                        ? true
                                        : true
                                "
                                index="/psychology"
                                class="title"
                                style="padding-left: 58px"
                                >心理辅导</el-menu-item
                            >
                            <el-menu-item
                                v-if="
                                    $globle.UserInfoJurisdiction('Disposition')
                                        ? true
                                        : true
                                "
                                index="/disposition"
                                class="title"
                                style="padding-left: 58px"
                                >处置</el-menu-item
                            >
                        </el-submenu>
                        <!-- 兴趣班 -->
                        <el-submenu index="/interestClass" v-if="false">
                            <template slot="title">
                                <img
                                    class="img"
                                    src="../assets/教务端pc/ca1d1a3dadefed8eadbca25a9a09a9f.png"
                                    alt=""
                                />
                                <span class="title">兴趣班</span>
                            </template>
                            <el-menu-item
                                index="/interestClass"
                                class="title"
                                style="padding-left: 58px"
                                >兴趣班</el-menu-item
                            >
                        </el-submenu>
                        <!-- 课后 -->
                        <el-submenu index="kehou" v-if="true">
                            <template slot="title">
                                <img
                                    class="img"
                                    src="../assets/教务端pc/shengxue.png"
                                    alt=""
                                />
                                <span class="title">课后</span>
                            </template>
                            <el-menu-item
                                v-if="true"
                                index="/attendanceclass"
                                class="title"
                                style="padding-left: 58px"
                                >考勤</el-menu-item
                            >
                            <el-menu-item
                                v-if="true"
                                index="/readover"
                                class="title"
                                style="padding-left: 58px"
                                >审批</el-menu-item
                            >
                        </el-submenu>
                        <!-- 升学 -->
                        <el-submenu index="shengxue" v-if="true">
                            <template slot="title">
                                <img
                                    class="img"
                                    src="../assets/教务端pc/shengxue.png"
                                    alt=""
                                />
                                <span class="title">升学</span>
                            </template>
                            <el-menu-item
                                v-if="true"
                                index="/manage"
                                class="title"
                                style="padding-left: 58px"
                                >升学管理</el-menu-item
                            >
                            <el-menu-item
                                v-if="true"
                                index="/adjust"
                                class="title"
                                style="padding-left: 58px"
                                >班级调整
                                <!-- <el-badge value="1"></el-badge> -->
                            </el-menu-item>
                            <el-menu-item
                                v-if="true"
                                index="/teacherconfiguration"
                                class="title"
                                style="padding-left: 58px"
                                >教师配置</el-menu-item
                            >
                            <el-menu-item
                                v-if="true"
                                index="/graduateManagement"
                                class="title"
                                style="padding-left: 58px"
                                >毕业生管理</el-menu-item
                            >
                        </el-submenu>
                        <!-- 巡检 -->
                        <el-submenu index="xungjian" v-if="true">
                            <template slot="title">
                                <img
                                    class="img"
                                    src="../assets/教务端pc/shengxue.png"
                                    alt=""
                                />
                                <span class="title">巡检</span>
                            </template>
                            <el-menu-item
                                v-if="true"
                                index="/todayInspection"
                                class="title"
                                style="padding-left: 58px"
                                >今日巡检</el-menu-item
                            >
                            <el-menu-item
                                v-if="true"
                                index="/arrangeInspection"
                                class="title"
                                style="padding-left: 58px"
                                >巡检查询
                                <!-- <el-badge value="1"></el-badge> -->
                            </el-menu-item>
                        </el-submenu>
                    </el-menu>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        handleOpen(key, keyPath) {
            // console.log(key, keyPath, "keyPath");
        },
        handleClose(key, keyPath) {
            // console.log(key, keyPath, "12");
        },
    },
};
</script>

<style scoped>
.logo {
    width: 100%;
    height: 120px;
}
.logo img {
    width: 56px;
    height: 56px;
    margin-top: 30px;
}
.el-col-12 {
    width: 100%;
}
.tac {
    font-size: 16px;
    font-weight: bold;
}
.elrowlist {
    height: calc(100vh - 300px);
    min-height: 800px;
    overflow: auto;
}
/* 边框 */
::v-deep .el-submenu .el-menu-item {
    min-width: 180px;
}
.el-menu-item {
    border-left: 3px solid #ffffff;
    box-sizing: border-box;
    height: 61px;
}
.el-submenu__title {
    border-left: 3px solid #e4f5ee;
    box-sizing: border-box;
}
.el-submenu .el-menu-item {
    height: 60px;
    line-height: 60px;
}
.el-menu-item.is-active {
    background-color: #e4f5ee !important;
    border-left: 3px solid #21af73;
    box-sizing: border-box;
}
.el-submenu__title:hover {
    background-color: #e4f5ee !important;
}
.el-menu-item:hover {
    background-color: #e4f5ee !important;
}
.img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}
.title {
    display: inline-block;
    width: 100px;
    text-align: left;
    padding-left: 8px;
}
/* 红点显示 */
::v-deep .el-badge__content {
    width: 10px !important;
    height: 10px;
    line-height: 10px;
    color: #f56c6c;
    border-radius: 50%;
    position: absolute;
    top: -20px;
    padding: 0px;
    margin: 0px;
    font-size: 8px;
}
</style>