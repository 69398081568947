<template>
    <div>
        <!-- 学生考勤  未使用 -->
        <div class="student" v-if="false">
            <div class="kaoq kaoq2">
                <span class="kaoq-span">学生考勤</span>
                <span class="title-span3"></span>
            </div>
        </div>
        <!-- 树形控件 -->
        <div class="tree">
            <el-tree
                ref="tree"
                class="tree"
                :data="data"
                node-key="ID"
                :props="defaultProps"
                :highlight-current="true"
                @node-click="handleNodeClick"
                :default-expanded-keys="treeExpandData"
            >
                <div class="custom-tree-node" slot-scope="{ data }">
                    <span class="custom-tree-node-label">{{
                        data.ORG_NAME
                    }}</span>
                    <!-- 统计 -->
                    <div class="custom-tree-node-div" v-if="false">
                        <!-- 1 -->
                        <span class="custom-tree-node-generation">{{
                            data.generation
                        }}</span>
                        <!-- 2 -->
                        <span class="custom-tree-node-span">{{
                            data.prepare
                        }}</span>
                    </div>
                </div>
            </el-tree>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        //  在props中接收父组件传过来的值
        //  获取ID
        STATGEIDclick: {
            type: Function,
        },
    },
    data() {
        return {
            // 单元数据
            data: [],
            treeExpandData: [],
            // 树形控件数据
            defaultProps: {
                children: "children",
                label: "label",
            },
        };
    },
    beforeMount() {
        this.SchoolTreeDropdownList();
        setTimeout(() => {
            this.fetchTreeData(this.data[0].ID);
            this.STATGEIDclick(this.data[0]);
        }, 1200);
    },
    methods: {
        // 默认高亮
        fetchTreeData(e) {
            this.$refs.tree.setCurrentKey(e);
        },
        // 树形控件
        handleNodeClick(data) {
            console.log(data, "树形控件点击");
            this.STATGEIDclick(data);
        },
        getRoleTreeRootNode(provincialCenterId) {
            this.treeExpandData.push(provincialCenterId);
        },
        // 获取树形列表
        SchoolTreeDropdownList() {
            let data = {
                SCHOOLID: this.$globle.userInfo.PARENT_ORG_INFO.ID,
            };
            this.$api.SchoolTreeDropdownList(data, (res) => {
                if (res.state) {
                    this.getRoleTreeRootNode(res.Data[0].ID);
                    this.data = res.Data;
                }
            });
        },
    },
};
</script>

<style scoped lang='scss'>
@import "../../style/_style.scss";
.student {
    width: 100%;
    height: 45px;
    padding: 5px 20px 10px 20px;
    box-sizing: border-box;
    border: 1px solid #f3f3f3;
}
.kaoq {
    min-width: 90px;
    box-sizing: border-box;
    padding-top: 3px;
    width: 10%;
    color: #333333;
    font-size: 14px;
    font-weight: bold;
}
.kaoq-span {
    display: block;
}
.title-span3 {
    display: block;
    width: 15px !important;
    height: 4px !important;
    border-radius: 2px;
    background: $colorlv;
    margin-top: 3px;
    margin-left: 24px;
}
// 全校考勤
.school {
    width: 100%;
    height: 60px;
    line-height: 60px;
    box-sizing: border-box;
    padding: 0px 8px 0px 40px;
    font-size: 14px;
}
.school-span {
    font-weight: bold;
}
/* 树形控件 */
.tree {
    // height: 70vh;
    min-height: 732px !important;
    overflow-x: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    margin-top: 10px;
}
.el-tree {
    background: #ffffff;
    color: #333333;
    width: 100%;
}
/* 未使用 */
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
}
/* 单元名字 */
.custom-tree-node-label {
    display: inline-block;
    width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.custom-tree-node-div {
    float: right;
}
.custom-tree-node-div span {
    display: inline-block;
}
/* 单元代课 */
.custom-tree-node-generation {
    width: 40px;
    height: 20px;
    text-align: center;
    background-color: $colorhui;
    color: $background;
    margin-right: 10px;
    line-height: 20px;
    font-size: 10px;
    box-sizing: border-box;
    letter-spacing: 1px;
}
/* 每个节点后面内容样式 */
.custom-tree-node-span {
    width: 40px;
    height: 20px;
    text-align: center;
    background-color: $colorlv;
    color: $background;
    margin-right: 10px;
    line-height: 20px;
    font-size: 10px;
    box-sizing: border-box;
    letter-spacing: 1px;
}
/* 节点样式 */
::v-deep .el-tree-node__content {
    height: 40px !important;
    border-left: 4px solid #ffffff !important;
    letter-spacing: 4px;
}
/* 点击后的样式 */
::v-deep .el-tree-node:focus > .el-tree-node__content {
    background-color: #e4f5ee !important;
    // font-weight: bold;
    /* 左侧边框 */
    /* border-left: 4px solid #21af73 !important; */
}
/* 高亮 */
::v-deep .el-tree-node.is-current > .el-tree-node__content {
    background-color: #e4f5ee !important;
    // font-weight: bold;
    /*  文本超出自动滚动启动 */
    /* animation: 4s wordsLoop linear infinite; */
    /* 左侧边框 */
    /* border-left: 4px solid #21af73 !important; */
}
/* 部门名字 */
.custom-tree-node-label {
    display: inline-block;
    width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: bold;
}
</style>