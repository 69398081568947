export default {
    // 用户信息
    userInfo: JSON.parse(localStorage.getItem("userInfo")) || [],
    // 数据字典
    Dictionaries: '',
    // 登录成功
    login: false,
    // ___g_URL_BASE : 'http://47.93.213.107:5000/api/',
    g_URL_BASE: 'http://47.93.213.107:5000',

    // 根据字典 类型 获取 DICGROUP
    getDictionariesDICGROUP(e) {
        var list = [];
        for (let i = 0; i < this.Dictionaries.length; i++) {
            if (this.Dictionaries[i].DICGROUP == e) {
                this.Dictionaries[i].neirong = []
                list.push(this.Dictionaries[i])
            }
        }
        return list
    },
    // 根据字典 组织机构类型 获取 DICGROUP
    getDictionariesDICGROUPID(e, exp) {
        var list = [];
        for (let i = 0; i < this.Dictionaries.length; i++) {
            if (this.Dictionaries[i].DICGROUP == e && this.Dictionaries[i].EXPLAIN == exp) {
                this.Dictionaries[i].neirong = []
                list.push(this.Dictionaries[i])
            }
        }
        return list
    },
    // 根据字典 分级DICGROUP 和 类型CONTENT  获取 ID
    getDictionariesID(e, a) {
        var list = '';
        for (let i = 0; i < this.Dictionaries.length; i++) {
            if (this.Dictionaries[i].DICGROUP == e && this.Dictionaries[i].CONTENT == a) {
                list = this.Dictionaries[i].ID
                return list
            } else {
                list = '无'
            }
        }
        return list
    },
    // 根据字典 根据ID  获取 当条信息
    getmessageID(e) {
        var list = {};
        for (let i = 0; i < this.Dictionaries.length; i++) {
            if (this.Dictionaries[i].ID == e) {
                list = this.Dictionaries[i]
                return list
            }
        }
        return list
    },

    // 用户信息权限管理
    UserInfoJurisdiction(e) {
        var list = [];
        for (let i = 0; i < this.userInfo.PERMISSIONS.length; i++) {
            if (this.userInfo.PERMISSIONS[i].PERMISSION_CODE == e) {
                list.push(this.userInfo.PERMISSIONS[i])
            }
        }
        if (list.length == 0) {
            return false
        } else {
            return true
        }
    }
}