
const disease = {
    // 时间转时间戳
    Datetimestamp(date) {
        var datefront =
            Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), -8, 0, 0) /
            1000;
        var dateafter =
            Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                23 - 8,
                59,
                59
            ) / 1000;
        return { datefront, dateafter }
    },
    // 时间戳转时间
    DatetimestampTime(originVal) {
        // console.log(originVal, 'originVal');
        var date = new Date(originVal * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000

        let Y = date.getFullYear() + '-';

        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';

        let D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() + '';

        let h = date.getHours() + ':';

        let m = date.getMinutes() + '';

        let s = date.getSeconds();

        return Y + M + D + ' ' + h + m;
    },
    // 时间戳转时间  2023-01-01
    DatetimestampTime2(originVal) {
        // console.log(originVal,'originVal');
        var date = new Date(originVal * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000

        let Y = date.getFullYear() + '-';

        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';

        let D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() + '';

        let h = date.getHours() + ':';

        let m = date.getMinutes() + ':';

        let s = date.getSeconds();

        return Y + M + D;
    },
    // 时间戳转时间
    Datetimestamp2(originVal) {
        // console.log(originVal,'originVal');
        var date = new Date(originVal * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000

        let Y = date.getFullYear() + '-';

        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';

        let D = date.getDate() + ' ';

        let h = date.getHours() < 10 ? ('0' + date.getHours()) + '-' : (date.getHours()) + '-';

        let m = date.getMinutes() + ':';

        let s = date.getSeconds();

        return M + D + h + m + s;
    },
    // 时间戳转时间  2023-01-01
    Datetimestamp3(originVal) {
        // console.log(originVal,'originVal');
        var date = new Date(originVal * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000

        let Y = date.getFullYear() + '-';

        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';

        let D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() + '';

        let h = date.getHours() + ':';

        let m = date.getMinutes() + ':';

        let s = date.getSeconds();

        return Y + M + D;
    },
    // 时间戳转时间  Y + M + D
    DatetimestampTimeYMD(originVal) {
        // console.log(originVal, 'originVal');
        var date = new Date(originVal * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000

        let Y = date.getFullYear() + '-';

        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';

        let D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() + '';

        let h = date.getHours() + ':';

        let m = date.getMinutes() + '';

        let s = date.getSeconds();

        return Y + M + D;
    },
    // 时间戳转时间  M + D
    DatetimestampTimeMD(originVal) {
        // console.log(originVal, 'originVal');
        var date = new Date(originVal * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000

        let Y = date.getFullYear() + '-';

        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';

        let D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() + '';

        let h = date.getHours() + ':';

        let m = date.getMinutes() + '';

        let s = date.getSeconds();

        return M + D;
    },
    // 时间长转时间  
    DatetimestampTimeMDhm(originVal) {
        // console.log(originVal, 'originVal');
        var date = new Date(originVal * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000

        let Y = date.getFullYear() + '-';

        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';

        let D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() + '';

        let h = date.getHours() + ':';

        let m = date.getMinutes() + '';

        let s = date.getSeconds();

        return M + D + ':' + h + m;
    },
    // 时间戳转时间  2023年01月01日
    DatetimestampYMD(originVal) {
        // console.log(originVal,'originVal');
        var date = new Date(originVal * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000

        let Y = date.getFullYear() + '年';

        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '月';

        let D = date.getDate() < 10 ? '0' + date.getDate() + '日' : date.getDate() + '日';

        let h = date.getHours() + ':';

        let m = date.getMinutes() + ':';

        let s = date.getSeconds();

        return Y + M + D;
    },
}
export default disease;