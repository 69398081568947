<template>
    <div class="back">
        <div class="content">
            <div class="content-text"></div>
            <div class="content-div">
                <div class="content-div-left">
                    <div style="margin-top: 250px">
                        <span>Welcome ！</span>
                        <span>绵阳市安州区校园晨检平台</span>
                    </div>
                </div>
                <div class="content-div-right">
                    <span class="welcome">欢迎登录</span>
                    <div class="input">
                        <input
                            type="text"
                            placeholder="用户名"
                            class="zhanghao"
                        />
                        <input type="text" placeholder="密码" class="mima" />
                        <div class="register">
                            <span>忘记密码？</span>
                            <span>注册</span>
                        </div>
                        <div class="denglu">登 录</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style scoped lang='scss'>
@import "../../style/_style.scss";
.back {
    width: 100%;
    height: 100vh;
    background-image: url("../../assets/登录/图层\ 1\ 拷贝@3x.png");
    background-size: 100%;

    padding: 10px;
    box-sizing: border-box;
}
.content {
    width: 60%;
    margin: 8% auto;
}
.content-text {
    width: 100%;
    height: 30px;
    line-height: 30px;
    color: #ffffff;
    font-size: 12px;
    letter-spacing: 2px;
}
.content-div {
    width: 100%;
    height: 400px;
    margin: 0 auto;
    border: 0.5px solid #ffffff;
    border-radius: 8px;
    display: flex;
    .content-div-left {
        width: 50%;
        height: 100%;
        background-image: url('../../assets/登录/36d36c237826358a1e37f60e9c3a416.png');
        background-repeat: no-repeat;
        background-position: 50% 30%;
        span {
            display: inline-block;
            width: 100%;
            font-size: 20.5px;
            color: #ffffff;
            box-sizing: border-box;
            padding-left: 60px;
            margin-top: 20px;
        }
    }
    .content-div-right {
        width: 50%;
        height: 100%;
        background-color: rgba($color: #ffffff, $alpha: 0.7);
        border-radius: 7px;
        .welcome {
            display: inline-block;
            width: 100%;
            text-align: center;
            height: 100px;
            line-height: 100px;
            color: $login;
            font-size: 20px;
            font-weight: bold;
        }
        .input {
            margin: 0 auto;
            width: 282px;
        }
        input {
            outline: none;
            width: 282px;
            height: 40px;
            border: 0.5px solid #aaaaaa;
            border-radius: 4px;
            background-repeat: no-repeat;
            box-sizing: border-box;
            padding-left: 32px;
            background-position: 7px 9px;
            font-size: 12px;
            color: #4b4141;
            background-color: rgba($color: #ffffff, $alpha: 0.5);
            letter-spacing: 1px;
        }
        .zhanghao {
            background-image: url("../../assets/登录/蒙版组 583@2x.png");
            margin-bottom: 20px;
        }
        .mima {
            background-image: url("../../assets/登录/蒙版组 584@2x.png");
            margin-bottom: 10px;
        }
    }
}
input::-webkit-input-placeholder {
    /* placeholder颜色  */
    color: #acacac;
}
// 注册
.register {
    width: 100%;
    color: #2f87c1;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
}
.denglu {
    width: 240px;
    height: 30px;
    line-height: 30px;
    margin: 0 auto;
    text-align: center;
    color: #ffffff;
    background: #2f87c1;
    border-radius: 4px;
    margin-top: 60px;
}
</style>