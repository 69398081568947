import mqtt from 'mqtt/dist/mqtt'

let client = null;
export function createConnect(topic, callback) {
    client = mqtt.connect(`ws://47.93.213.107:18087/mqtt`, {  // ws这个为连接类型，还有wss 为加密型
        connectTimeout: 40000,
        clientId: '',
        username: 'admin',
        password: 'root123456',
        clean: true
    })
    // mqtt连接
    client.on('connect', (e) => {
        if (typeof topic == 'string') {
            // 订阅一个topic
            client.subscribe(topic, { qos: 2 }, (err) => {
                if (!err) {
                    console.log('单个消息订阅成功1')
                } else {
                    console.log('单个消息订阅失败1')
                }
            })
        } else {
            // 数组格式，订阅多个topic
            topic.forEach(item => {
                client.subscribe(item, { qos: 2 }, (err) => {
                    if (!err) {
                        console.log('多个消息订阅成功2')
                    } else {
                        console.log('多个消息订阅失败2')
                    }
                })
            })
        }

    })
    // 接收消息处理
    client.on('message', (topicres, message, packet) => {
        if (callback) {
            callback(topicres, message.toString())
        }
    })
    client.on('error', (err) => {
        console.log('mqtt连接失败', err)
    })
    client.on('disconnect', (back) => {
        console.log('mqtt连接失败2', back)
    })
}

// 发送消息
export function doPublish(theme, message) {
    let client = mqtt.connect(`ws://47.93.213.107:18087/mqtt`, {  // ws这个为连接类型，还有wss 为加密型
        connectTimeout: 40000,
        clientId: '',
        username: 'admin',
        password: 'root123456',
        clean: true
    })
    const topic = theme,
        qos = 2,
        payload = message
    client.publish(topic, payload, qos, (error) => {
        if (error) {
            console.log('发布错误', error)
        }
    })
}
export function subscribeTopic(topic) {
    client.subscribe(topic, { qos: 2 }, (err) => {
        if (!err) {
            console.log('消息订阅成功')
        } else {
            console.log('消息订阅失败')
        }
    })
}
export function unsubscribe(topic) {
    client.unsubscribe(topic, (err) => {
        if (err) {
            console.log(err)
        } else {
            console.log('Unsubscribe')
        }
    })
}

export function endConnect(topic) {
    if (client) {
        client.unsubscribe(topic, (err) => {
            if (err) {
                console.log(err)
            } else {
                console.log('Unsubscribe')
            }
        })
        client.end(true, () => {
            console.log('clientServer已关闭')
            client = null
        })
    }

}




