import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/LoginRegister/Login.vue'
import Register from '../views/LoginRegister/Register.vue'
import ClassroomCheck from '../views/check/ClassroomCheck.vue'
import HomePage from '../views/HomePage/HomePage.vue'

Vue.use(VueRouter)

const routes = [
    // 重定向
    {
        path: '/',
        redirect: '/classroomCheck'
    },
    // 登录
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            title: '登录',
            keepAlive: false, // 需要被缓存
        }
    },
    // 注册
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            title: '注册',
            keepAlive: false, // 需要被缓存
        }
    },
    // 首页
    {
        path: '/homePage', //
        name: 'homePage',
        component: HomePage,
        meta: {
            title: '首页',
            keepAlive: true, // 需要被缓存
        }
    },
    // 课堂考勤
    {
        path: '/classroomCheck', //
        name: 'classroomCheck',
        component: ClassroomCheck,
        // component: () => import('../views/check/ClassroomCheck.vue'),
        meta: {
            title: '课堂考勤',
            keepAlive: true, // 需要被缓存
        }
    },
    // 兴趣班考勤
    {
        path: '/interestCheck',
        name: 'interestCheck',
        component: () => import('../views/check/InterestCheck.vue'),
        meta: {
            title: '兴趣班考勤',
            keepAlive: true, // 需要被缓存
        }
    },
    // 教职工考勤
    {
        path: '/facultyCheck',
        name: 'facultyCheck',
        component: () => import('../views/check/FacultyCheck.vue'),
        meta: {
            title: '教职工考勤',
            keepAlive: true, // 需要被缓存
        }
    },
    // 健康数据
    {
        path: '/healthdata',
        name: 'healthdata',
        component: () => import('../views/Healthdata/Healthdata.vue'),
        meta: {
            title: '今日',
            keepAlive: true, // 需要被缓存
        }
    },
    // 病例
    {
        path: '/case',
        name: 'case',
        component: () => import('../views/Healthdata/Case.vue'),
        meta: {
            title: '病例',
            keepAlive: true, // 需要被缓存
        }
    },
    // 累计
    {
        path: '/cumulative',
        name: 'cumulative',
        component: () => import('../views/Healthdata/Cumulative.vue'),
        meta: {
            title: '累计',
            keepAlive: true, // 需要被缓存
        }
    },
    // 健康数据
    {
        path: '/healthy',
        name: 'healthy',
        component: () => import('../views/check/Healthy.vue'),
        meta: {
            title: '健康数据',
            keepAlive: true, // 需要被缓存
        }
    },
    // 学生换课
    {
        path: '/studentTransfer',
        name: 'studentTransfer',
        component: () => import('../views/Transfercourses/StudentTransfer.vue'),
        meta: {
            title: '学生换课',
            keepAlive: true, // 需要被缓存
        }
    },
    // 老师换课
    {
        path: '/teacherTransfer',
        name: 'teacherTransfer',
        component: () => import('../views/Transfercourses/TeacherTransfer.vue'),
        meta: {
            title: '老师换课',
            keepAlive: true, // 需要被缓存
        }
    },
    // 人员
    {
        path: '/personnel',
        name: 'personnel',
        component: () => import('../views/departmentstaff/personnel/Personnel.vue'),
        meta: {
            title: '人员',
            keepAlive: true, // 需要被缓存
        }
    },
    {
        path: '/personnel2',
        name: 'personnel2',
        component: () => import('../views/departmentstaff/personnel2/Personnel.vue'),
        meta: {
            title: '人员2',
            keepAlive: true, // 需要被缓存
        }
    },
    // 学生
    {
        path: '/student',
        name: 'student',
        component: () => import('../views/departmentstaff/Student/Student.vue'),
        meta: {
            title: '学生',
            keepAlive: true, // 需要被缓存
        }
    },
    // 教师
    {
        path: '/teacher',
        name: 'teacher',
        component: () => import('../views/departmentstaff/Teacher/Teacher.vue'),
        meta: {
            title: '教师',
            keepAlive: true, // 需要被缓存
        }
    },
    // 部门
    {
        path: '/department',
        name: 'department',
        component: () => import('../views/departmentstaff/department/Department.vue'),
        meta: {
            title: '部门',
            keepAlive: true, // 需要被缓存
        }
    },
    // 部门 2.0
    {
        path: '/department2',
        name: 'department2',
        component: () => import('../views/departmentstaff/department2/Department2.vue'),
        meta: {
            title: '部门',
            keepAlive: true, // 需要被缓存
        }
    },
    // 职务
    {
        path: '/duty',
        name: 'duty',
        component: () => import('../views/departmentstaff/Duty/Duty.vue'),
        meta: {
            title: '职务',
            keepAlive: true, // 需要被缓存
        }
    },
    // 消息
    {
        path: '/news',
        name: 'news',
        component: () => import('../views/news/News.vue'),
        meta: {
            title: '消息',
            keepAlive: true, // 需要被缓存
        }
    },
    // 考勤类
    {
        path: '/attendance',  //首页
        name: 'attendance',
        component: () => import('../views/query/Attendance.vue'),
        meta: {
            title: '考勤类',
            keepAlive: true, // 需要被缓存
        }
    },
    // 病症类
    {
        path: '/disease',
        name: 'disease',
        component: () => import('../views/query/Disease.vue'),
        meta: {
            title: '病症类',
            keepAlive: true, // 需要被缓存
        }
    },
    // 性别类
    {
        path: '/gender',
        name: 'gender',
        component: () => import('../views/query/Gender.vue'),
        meta: {
            title: '性别类',
            keepAlive: true, // 需要被缓存
        }
    },
    // 我的   托管生注册
    {
        path: '/studentRegistration',
        name: 'studentRegistration',
        component: () => import('../views/my/StudentRegistration.vue'),
        meta: {
            title: '托管生注册',
            keepAlive: true, // 需要被缓存
        }
    },
    // 我的   学校公告
    {
        path: '/notice',
        name: 'notice',
        component: () => import('../views/my/Notice.vue'),
        meta: {
            title: '学校公告',
            keepAlive: true, // 需要被缓存
        }
    },
    // 我的   报表查询
    {
        path: '/reportform',
        name: 'reportform',
        component: () => import('../views/my/Reportform.vue'),
        meta: {
            title: '报表查询',
            keepAlive: true, // 需要被缓存
        }
    },
    // 我的   兴趣班管理
    {
        path: '/classTube',
        name: 'classTube',
        component: () => import('../views/my/ClassTube.vue'),
        meta: {
            title: '兴趣班管理',
            keepAlive: true, // 需要被缓存
        }
    },
    // 我的   健康考勤管理
    {
        path: '/healthyant',
        name: 'healthyant',
        component: () => import('../views/my/Healthy.vue'),
        meta: {
            title: '健康考勤管理',
            keepAlive: true, // 需要被缓存
        }
    },
    // 我的   功能设置
    {
        path: '/function',
        name: 'function',
        component: () => import('../views/my/Function.vue'),
        meta: {
            title: '功能设置',
            keepAlive: true, // 需要被缓存
        }
    },
    // 我的   缴费管理
    {
        path: '/pay',
        name: 'pay',
        component: () => import('../views/my/Pay.vue'),
        meta: {
            title: '缴费管理',
            keepAlive: true, // 需要被缓存
        }
    },
    // 我的   来访记录
    {
        path: '/visit',
        name: 'visit',
        component: () => import('../views/my/Visit.vue'),
        meta: {
            title: '来访记录',
            keepAlive: true, // 需要被缓存
        }
    },
    // 我的   班级荣誉设置
    {
        path: '/honor',
        name: 'honor',
        component: () => import('../views/my/Honor.vue'),
        meta: {
            title: '班级荣誉设置',
            keepAlive: true, // 需要被缓存
        }
    },
    // 我的   班级课程设置
    {
        path: '/curriculum',
        name: 'curriculum',
        component: () => import('../views/my/Curriculum.vue'),
        meta: {
            title: '课程设置',
            keepAlive: true, // 需要被缓存
        }
    },
    // 我的   预警设置
    {
        path: '/warning2',
        name: 'warning',
        component: () => import('../views/my/Warning.vue'),
        meta: {
            title: '预警',
            keepAlive: true, // 需要被缓存
        }
    },
    // 校医  今日
    {
        path: '/schoolDoctorDay',
        name: 'schoolDoctorDay',
        component: () => import('../views/schooldoctor/SchoolDoctorDay.vue'),
        meta: {
            title: '今日',
            keepAlive: true, // 需要被缓存
        }
    },
    // 校医
    {
        path: '/schoolDoctor',
        name: 'schoolDoctor',
        component: () => import('../views/schooldoctor/SchoolDoctor.vue'),
        meta: {
            title: '校医',
            keepAlive: true, // 需要被缓存
        }
    },
    // 校医  已标注
    {
        path: '/marked',
        name: 'marked',
        component: () => import('../views/schooldoctor/Marked.vue'),
        meta: {
            title: '已标注',
            keepAlive: true, // 需要被缓存
        }
    },
    // 校医  心理
    {
        path: '/psychology',
        name: 'psychology',
        component: () => import('../views/schooldoctor/Psychology.vue'),
        meta: {
            title: '心理',
            keepAlive: true, // 需要被缓存
        }
    },
    // 校医  处置
    {
        path: '/disposition',
        name: 'disposition',
        component: () => import('../views/schooldoctor/Disposition.vue'),
        meta: {
            title: '心理',
            keepAlive: true, // 需要被缓存
        }
    },
    // 系统管理
    {
        path: '/system',
        name: 'system',
        component: () => import('../views/system/System.vue'),
        meta: {
            title: '系统管理',
            keepAlive: true, // 需要被缓存
        }
    },
    // 预警列表
    {
        path: '/warninglist',
        name: 'warninglist',
        component: () => import('../views/Warning/Warninglist.vue'),
        meta: {
            title: '预警列表',
            keepAlive: true, // 需要被缓存
        }
    },
    // 兴趣班
    {
        path: '/interestClass',
        name: 'interestClass',
        component: () => import('../views/InterestClass/InterestClass.vue'),
        meta: {
            title: '兴趣班',
            keepAlive: true, // 需要被缓存
        }
    },
    // 升学
    // 升学管理
    {
        path: '/manage',
        name: 'manage',
        component: () => import('../views/HigherSchool/Manage.vue'),
        meta: {
            title: '升学管理',
            keepAlive: true, // 需要被缓存
        }
    },
    // 班级调整
    {
        path: '/adjust',
        name: 'adjust',
        component: () => import('../views/HigherSchool/Adjust.vue'),
        meta: {
            title: '班级调整',
            keepAlive: true, // 需要被缓存
        }
    },
    // 老师调整
    {
        path: '/teacherconfiguration',
        name: 'teacherconfiguration',
        component: () => import('../views/HigherSchool/TeacherConfiguration.vue'),
        meta: {
            title: '老师调整',
            keepAlive: true, // 需要被缓存
        }
    },
    // 毕业生管理
    {
        path: '/graduateManagement',
        name: 'graduateManagement',
        component: () => import('../views/HigherSchool/GraduateManagement.vue'),
        meta: {
            title: '老师调整',
            keepAlive: true, // 需要被缓存
        }
    },
    // 巡检
    // 今日巡检
    {
        path: '/todayInspection',
        name: 'todayInspection',
        component: () => import('../views/Inspection/todayInspection/todayInspection.vue'),
        meta: {
            title: '今日巡检',
            keepAlive: true, // 需要被缓存
        }
    },
    // 巡检查询
    {
        path: '/arrangeInspection',
        name: 'arrangeInspection',
        component: () => import('../views/Inspection/arrangeInspection/arrangeInspection.vue'),
        meta: {
            title: '巡检查询',
            keepAlive: true, // 需要被缓存
        }
    },

    // 课后
    // 兴趣班考勤
    {
        path: '/attendanceclass',
        name: 'attendanceclass',
        component: () => import('../views/Afterclass/Attendance.vue'),
        meta: {
            title: '兴趣班考勤',
            keepAlive: true, // 需要被缓存
        }
    },
    // 审批
    {
        path: '/readover',
        name: 'readover',
        component: () => import('../views/Afterclass/Readover.vue'),
        meta: {
            title: '审批',
            keepAlive: true, // 需要被缓存
        }
    },
]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
    // console.log(to, '路由守卫');
    if (to.name == 'login' || to.name == 'register') {
        next()
    } else {
        if (localStorage.token) {
            next()
        } else {
            alert('你还未登录，请先登录')
            next('/login')
        }
    }
})
export default router
