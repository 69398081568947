<template>
    <!-- 首页 -->
    <div class="home">
        <div class="left">
            <!-- 日历 -->
            <div class="calendar">
                <el-calendar v-model="datevalue"> </el-calendar>
            </div>
            <!-- 我的待办 -->
            <div class="unprocessed">
                <div class="header">
                    <div>
                        <div class="header-name">
                            <span>我的待办</span>
                            <span></span>
                        </div>
                    </div>
                    <div class="select">
                        <el-select v-model="value" placeholder="近几日">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <!-- 内容 -->
                <div class="waitinglist">
                    <div class="tr" v-for="item in 10" :key="item">
                        <span></span>
                        <span>A区巡检</span>
                        <span>12-13</span>
                        <span>16:30</span>
                        <span>详情</span>
                    </div>
                </div>
            </div>
            <!-- 公告通知 -->
            <div class="notice">
                <div class="header">
                    <div>
                        <div class="header-name">
                            <span>公告通知</span>
                            <span></span>
                        </div>
                        <div class="header-name">
                            <span class="spancolor">消息</span>
                            <span class="spanback"></span>
                        </div>
                    </div>
                    <div class="select">
                        <el-select v-model="value" placeholder="近几日">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <!-- 内容 -->
                <div class="noticelist">
                    <div class="tr" v-for="item in 10" :key="item">
                        <span>2023年寒假放假通知</span>
                        <span>2023-12-07</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="right">
            <!-- 功能导航 -->
            <div class="navigation">
                <div class="navigationtd" v-for="item in 10" :key="item">
                    <img
                        src="../../assets/laifang.png"
                        alt=""
                        v-if="item == 2"
                    />
                    <img
                        src="../../assets/tiaohuanke.png"
                        alt=""
                        v-if="item != 2"
                    />
                    <span>调换课</span>
                </div>
            </div>
            <!-- 晨检 -->
            <div class="morningcheck">
                <span class="left-pointto">晨</span>
                <div v-for="item in 10" :key="item" class="pointto">
                    <span
                        :class="
                            item == 6
                                ? 'matter'
                                : '' || item == 7
                                ? 'illness'
                                : ''
                        "
                        >应到</span
                    >
                    <span
                        :class="
                            item == 6
                                ? 'matter'
                                : '' || item == 7
                                ? 'illness'
                                : ''
                        "
                        >800</span
                    >
                    <span v-if="item != 10"></span>
                </div>
                <span class="right-pointto">晨</span>
            </div>
            <!-- 今日上报 ，健康数据 -->
            <div class="escalation">
                <!-- 今日上报 -->
                <div class="todayescalation">
                    <div class="header">
                        <div>
                            <div class="header-name">
                                <span>今日上报</span>
                                <span></span>
                            </div>
                        </div>
                        <div class="span">
                            <span>更多</span>
                        </div>
                    </div>
                    <div class="escalationlist">
                        <div
                            v-for="item in 10"
                            :key="item"
                            class="escalationlist-div"
                        >
                            <div>
                                <span class="strip"></span>
                                <span class="region">A区巡检</span>
                            </div>
                            <span class="teacher">李老师</span>
                            <span style="width: 150px">水管破裂</span>
                            <span style="width: 70px">9:53</span>
                            <span style="width: 50px; color: #519770"
                                >详情</span
                            >
                        </div>
                    </div>
                </div>
                <!-- 健康数据，预警记录 -->
                <div class="healthdata">
                    <div class="header">
                        <div>
                            <div
                                class="header-name"
                                @click="Warningswitching(1)"
                            >
                                <span
                                    :class="
                                        Warningswitchingdate == 1
                                            ? ''
                                            : 'spancolor'
                                    "
                                    >健康数据</span
                                >
                                <span
                                    :class="
                                        Warningswitchingdate == 1
                                            ? ''
                                            : 'spanback'
                                    "
                                ></span>
                            </div>
                            <div
                                class="header-name"
                                @click="Warningswitching(2)"
                            >
                                <span
                                    :class="
                                        Warningswitchingdate == 2
                                            ? ''
                                            : 'spancolor'
                                    "
                                    >预警记录</span
                                >
                                <span
                                    :class="
                                        Warningswitchingdate == 2
                                            ? ''
                                            : 'spanback'
                                    "
                                ></span>
                            </div>
                        </div>
                        <div class="jiantou">
                            <img src="../../assets/lvzuo.png" alt="" />
                            <img src="../../assets/lvyou.png" alt="" />
                        </div>
                    </div>
                    <!-- 图表 -->
                    <!-- v-if="Warningswitchingdate == 1" -->
                    <div class="chart">
                        <div class="chart" id="main" ref="echarts"></div>
                    </div>
                </div>
            </div>
            <!-- 调换课 ， 餐食 -->
            <div class="changecoursesandmeals">
                <div class="changecourses">
                    <div class="header">
                        <div>
                            <div class="header-name">
                                <span>调课申请</span>
                                <span></span>
                            </div>
                            <div class="header-name">
                                <span class="spancolor">调课记录</span>
                                <span class="spanback"></span>
                            </div>
                        </div>
                        <div class="select">
                            <el-select v-model="value" placeholder="近几日">
                                <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <!-- 调换课 -->
                    <div class="escalationlist">
                        <div
                            v-for="item in 10"
                            :key="item"
                            class="escalationlist-div"
                        >
                            <div>
                                <span class="strip"></span>
                                <span class="region">杨萍</span>
                            </div>
                            <span>三年级 2 班</span>
                            <span>语文</span>
                            <span>第一节</span>
                            <span>换</span>
                            <span style="color: #cdcdcd">12-07（星期三）</span>
                        </div>
                    </div>
                </div>
                <div class="meals">
                    <div class="header">
                        <div>
                            <div class="header-name">
                                <span>餐食</span>
                                <span></span>
                            </div>
                            <div class="header-name">
                                <span class="spancolor">餐食计划</span>
                                <span class="spanback"></span>
                            </div>
                        </div>
                        <div class="select">
                            <el-select v-model="value" placeholder="近几日">
                                <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <!-- 餐食 -->
                    <div class="escalationlist">
                        <div
                            v-for="item in 10"
                            :key="item"
                            class="escalationlist-div"
                        >
                            <div class="escalationlist-divleft">
                                <div>
                                    <span class="morning">早</span>
                                    <img
                                        src="../../assets/lvsetupian.png"
                                        alt=""
                                    />
                                </div>
                                <div>
                                    <span class="morning">中</span>
                                    <img
                                        src="../../assets/lvsetupian.png"
                                        alt=""
                                    />
                                </div>
                                <div>
                                    <span class="morning">晚</span>
                                    <img
                                        src="../../assets/huisetupian.png"
                                        alt=""
                                    />
                                </div>
                            </div>
                            <span>食堂（1）</span>
                            <span style="color: #cdcdcd">12-07（星期三）</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as echarts from "echarts";
export default {
    data() {
        return {
            // 日历
            datevalue: new Date(),
            // 待办日期筛选
            options: [
                {
                    value: "近30日",
                    label: "近30日",
                },
                {
                    value: "近7日",
                    label: "近7日",
                },
                {
                    value: "近3日",
                    label: "近3日",
                },
            ],
            value: "",
            // 健康数据，预警记录 切换
            Warningswitchingdate: 1,
        };
    },
    components: {},
    created() {},
    beforeMount() {},
    methods: {
        // 健康数据，预警记录 切换
        Warningswitching(e) {
            this.Warningswitchingdate = e;
            console.log(this.Warningswitchingdate, "this.Warningswitchingdate");
        },
    },
    // 监听日历变化
    watch: {
        datevalue(item1, item2) {
            // 监听datevalue变化获取数据
            let date = this.$date.Datetimestamp(item1).datefront;
        },
    },
    // 图表样式
    mounted() {
        var app = {};
        var chartDom = document.getElementById("main");
        var myChart = echarts.init(chartDom);
        var option;

        const posList = [
            "left",
            "right",
            "top",
            "bottom",
            "inside",
            "insideTop",
            "insideLeft",
            "insideRight",
            "insideBottom",
            "insideTopLeft",
            "insideTopRight",
            "insideBottomLeft",
            "insideBottomRight",
        ];
        app.configParameters = {
            rotate: {
                min: -90,
                max: 90,
            },
            align: {
                options: {
                    left: "left",
                    center: "center",
                    right: "right",
                },
            },
            verticalAlign: {
                options: {
                    top: "top",
                    middle: "middle",
                    bottom: "bottom",
                },
            },
            position: {
                options: posList.reduce(function (map, pos) {
                    map[pos] = pos;
                    return map;
                }, {}),
            },
            distance: {
                min: 0,
                max: 100,
            },
        };
        app.config = {
            rotate: 90,
            align: "left",
            verticalAlign: "middle",
            position: "insideBottom",
            distance: 15,
            onChange: function () {
                const labelOption = {
                    rotate: app.config.rotate,
                    align: app.config.align,
                    verticalAlign: app.config.verticalAlign,
                    position: app.config.position,
                    distance: app.config.distance,
                };
                myChart.setOption({
                    series: [
                        {
                            label: labelOption,
                        },
                        {
                            label: labelOption,
                        },
                        {
                            label: labelOption,
                        },
                        {
                            label: labelOption,
                        },
                    ],
                });
            },
        };
        const labelOption = {
            show: true,
            position: app.config.position,
            distance: app.config.distance,
            align: app.config.align,
            verticalAlign: app.config.verticalAlign,
            rotate: app.config.rotate,
            formatter: "{c}  {name|{a}}",
            fontSize: 16,
            rich: {
                name: {},
            },
        };
        option = {
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "shadow",
                },
            },
            legend: {
                data: ["传染病", "常见病", "慢病"],
                bottom: "6%",
            },
            toolbox: {
                show: false,
                orient: "vertical",
                left: "right",
                top: "center",
                feature: {
                    mark: { show: true },
                    dataView: { show: true, readOnly: false },
                    magicType: { show: true, type: ["line", "bar", "stack"] },
                    restore: { show: true },
                    saveAsImage: { show: true },
                },
            },
            xAxis: [
                {
                    type: "category",
                    axisTick: { show: false },
                    data: [
                        "12-01",
                        "12-02",
                        "12-03",
                        "12-04",
                        "12-05",
                        "12-06",
                        "12-07",
                    ],
                },
            ],
            yAxis: [
                {
                    type: "value",
                },
            ],
            series: [
                {
                    name: "传染病",
                    type: "bar",
                    barGap: 0,
                    itemStyle: {
                        color: "#FB923E",
                        borderRadius: 4,
                    },
                    emphasis: {
                        focus: "series",
                    },
                    data: [30, 32, 30, 34, 39, 12, 0],
                },
                {
                    name: "常见病",
                    type: "bar",
                    itemStyle: {
                        color: "#519770",
                        borderRadius: 4,
                    },
                    emphasis: {
                        focus: "series",
                    },
                    data: [22, 18, 19, 23, 29, 6, 1],
                },
                {
                    name: "慢病",
                    type: "bar",
                    itemStyle: {
                        color: "#A5A5A5",
                        borderRadius: 4,
                    },
                    emphasis: {
                        focus: "series",
                    },
                    data: [15, 32, 21, 14, 19, 1, 1],
                },
            ],
        };

        option && myChart.setOption(option);
    },
};
</script>

<style scoped lang='scss'>
// 首页
.home {
    width: 100%;
    display: flex;
}
.left {
    width: 363px;
    height: 100%;
    .calendar {
        width: 100%;
        height: 283px;
        margin-bottom: 10px;
        background: #ffffff;
        border-radius: 16px;
        box-sizing: border-box;
        padding: 5px;
    }
    .unprocessed {
        width: 100%;
        height: 344px;
        margin-bottom: 10px;
        background: #ffffff;
        border-radius: 16px;
        .waitinglist {
            width: 100%;
            height: 280px;
            overflow: auto;
            box-sizing: border-box;
            padding: 0px 20px 10px 20px;
            .tr {
                height: 35px;
                line-height: 35px;
                font-size: 14px;
                letter-spacing: 1px;
                display: flex;
                span {
                    display: inline-block;
                }
                span:nth-child(1) {
                    width: 7px;
                    height: 7px;
                    border-radius: 50%;
                    background: #519770;
                    margin-top: 14px;
                }
                span:nth-child(2) {
                    width: 150px;
                    padding-left: 5px;
                }
                span:nth-child(3) {
                    width: 50px;
                }
                span:nth-child(4) {
                    width: 50px;
                }
                span:nth-child(5) {
                    width: 70px;
                    text-align: right;
                    color: #519770;
                }
            }
        }
    }
    .notice {
        width: 100%;
        min-height: 310px;
        height: calc(100vh - 750px);
        background: #ffffff;
        border-radius: 16px;
        .noticelist {
            // height: 240px;
            min-height: 240px;
            height: calc(100vh - 820px);
            overflow: auto;
            box-sizing: border-box;
            padding: 0px 11px 10px 11px;
            .tr {
                height: 35px;
                line-height: 35px;
                font-size: 14px;
                letter-spacing: 1px;
                display: flex;
                background: #f9f9f9;
                margin-bottom: 8px;
                box-sizing: border-box;
                padding: 0px 11px;
                span {
                    display: inline-block;
                }
                span:nth-child(1) {
                    width: 230px;
                }
                span:nth-child(2) {
                    flex-grow: 1;
                }
            }
        }
    }
}
// 表头
.header {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
    margin-bottom: 10px;
    .header-name {
        width: 80px;
        height: 100%;
        text-align: center;
        margin-left: 10px;
        box-sizing: border-box;
        padding-top: 15px;
        display: inline-block;
        span:nth-child(1) {
            display: block;
            color: #353535;
            font-size: 15px;
            font-weight: bold;
            letter-spacing: 2px;
        }
        span:nth-child(2) {
            display: inline-block;
            width: 43px;
            height: 4px;
            border-radius: 12px 12px 0px 0px;
            background: #519770;
        }
        .spancolor {
            color: #cdcdcd !important;
        }
        .spanback {
            background: #ffffff !important;
        }
    }
    .select {
        margin-right: 5px;
    }
    .span {
        width: 70px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        color: #519770;
        font-size: 14px;
        letter-spacing: 2px;
    }
    .jiantou {
        height: 50px;
        line-height: 59px;
        span {
            display: inline-block;
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            color: #ffffff;
            border-radius: 50%;
            background: #519770;
            margin-right: 20px;
        }
        img {
            width: 20px;
            height: 20px;
            margin-right: 20px;
        }
    }
}
.right {
    flex-grow: 1;
    margin-left: 10px;
    .navigation {
        width: 100%;
        height: 148px;
        background: #ffffff;
        border-radius: 16px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-around;
        .navigationtd {
            width: 65px;
            height: 100px;
            text-align: center;
            box-sizing: border-box;
            padding-top: 20px;
            img {
                width: 65px;
                height: 65px;
                margin-bottom: 10px;
            }
            span {
                font-size: 16px;
                color: #757575;
                letter-spacing: 1px;
            }
        }
    }
    .morningcheck {
        width: 100%;
        height: 124px;
        background: #ffffff;
        border-radius: 16px;
        margin-bottom: 10px;
        border: 1px solid #519770;
        display: flex;
        box-sizing: border-box;
        padding: 20px 0px;
        position: relative;
        justify-content: space-around;
        .left-pointto {
            display: inline-block;
            width: 20px;
            height: 77px;
            position: absolute;
            left: 0px;
            background-image: url("../../assets/chenjian.png");
            background-size: 100%;
            font-size: 14px;
            font-weight: bold;
            color: #ffffff;
            line-height: 77px;
            text-align: center;
        }
        .pointto {
            width: 135px;
            height: 77px;
            // border-right: 1px solid #eeeeee;
            span {
                display: inline-block;
                text-align: center;
            }
            span:nth-child(1) {
                width: 115px;
                height: 15px;
                line-height: 15px;
                font-size: 16px;
                letter-spacing: 2px;
            }
            span:nth-child(2) {
                width: 115px;
                height: 50px;
                line-height: 55px;
                font-size: 30px;
                font-weight: bold;
            }
            span:nth-child(3) {
                width: 4px;
                height: 43px;
                background: #519770;
                border-radius: 12px 0px 0px 12px;
            }
            // 事假
            .matter {
                color: #8596e5;
            }
            .illness {
                color: #f57272;
            }
        }
        .right-pointto {
            display: inline-block;
            width: 20px;
            height: 77px;
            position: absolute;
            right: 0px;
            background-image: url("../../assets/chenjianyou.png");
            background-size: 100%;
            font-size: 14px;
            font-weight: bold;
            color: #ffffff;
            line-height: 77px;
            text-align: center;
        }
    }
    .escalation {
        width: 100%;
        height: 344px;
        margin-bottom: 10px;
        display: flex;
        .todayescalation {
            width: 50%;
            height: 344px;
            background: #ffffff;
            border-radius: 16px;
            // 上报
            .escalationlist {
                width: 100%;
                height: 270px;
                overflow: auto;
                box-sizing: border-box;
                padding: 0px 15px;
                margin: 10px 0px;
                .escalationlist-div {
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    background-color: #f9f9f9;
                    font-size: 14px;
                    letter-spacing: 2px;
                    border-radius: 5px;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px;
                    span {
                        display: inline-block;
                        height: 40px;
                        line-height: 40px;
                        margin-left: 10px;
                        white-space: nowrap; /* 不换行 */
                        overflow: hidden; /* 超出隐藏 */
                        text-overflow: ellipsis; /* 超出部分显示省略号 */
                    }
                    .strip {
                        width: 6px;
                        height: 18px;
                        border-radius: 15px;
                        background-color: #989898;
                        vertical-align: middle;
                    }
                    .region {
                        width: 200px;
                        height: 40px;
                        line-height: 40px;
                        margin-left: 5px;
                        vertical-align: middle;
                    }
                    .teacher {
                        width: 100px;
                    }
                }
            }
        }
        .healthdata {
            width: calc(50% - 10px);
            margin-left: 10px;
            height: 344px;
            background: #ffffff;
            border-radius: 16px;
            .chart {
                width: 100%;
                height: 290px;
            }
        }
    }
    .changecoursesandmeals {
        width: 100%;
        min-height: 310px;
        height: calc(100vh - 750px);
        margin-bottom: 10px;
        display: flex;
        .changecourses {
            width: 50%;
            // height: 310px;
            background: #ffffff;
            border-radius: 16px;
            // 调课申请
            .escalationlist {
                width: 100%;
                min-height: 240px;
                height: calc(100vh - 820px);
                overflow: auto;
                box-sizing: border-box;
                padding: 0px 15px;
                margin: 10px 0px;
                .escalationlist-div {
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    background-color: #f9f9f9;
                    font-size: 14px;
                    letter-spacing: 2px;
                    border-radius: 5px;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px;
                    span {
                        display: inline-block;
                        height: 40px;
                        line-height: 40px;
                        margin-left: 10px;
                        white-space: nowrap; /* 不换行 */
                        overflow: hidden; /* 超出隐藏 */
                        text-overflow: ellipsis; /* 超出部分显示省略号 */
                    }
                    .strip {
                        width: 6px;
                        height: 18px;
                        border-radius: 15px;
                        background-color: #989898;
                        vertical-align: middle;
                    }
                    .region {
                        width: 90px;
                        height: 40px;
                        line-height: 40px;
                        margin-left: 5px;
                        vertical-align: middle;
                    }
                }
            }
        }
        .meals {
            width: calc(50% - 10px);
            margin-left: 10px;
            min-height: 310px;
            background: #ffffff;
            border-radius: 16px;
            // 餐食
            .escalationlist {
                width: 100%;
                min-height: 240px;
                height: calc(100vh - 820px);
                overflow: auto;
                box-sizing: border-box;
                padding: 0px 15px;
                margin: 10px 0px;
                .escalationlist-div {
                    width: 100%;
                    height: 40px;
                    line-height: 37px;
                    background-color: #f9f9f9;
                    font-size: 14px;
                    letter-spacing: 2px;
                    border-radius: 5px;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px;
                    span {
                        display: inline-block;
                        height: 40px;
                        line-height: 40px;
                        margin-left: 10px;
                        white-space: nowrap; /* 不换行 */
                        overflow: hidden; /* 超出隐藏 */
                        text-overflow: ellipsis; /* 超出部分显示省略号 */
                    }
                    .escalationlist-divleft {
                        display: flex;
                        justify-content: space-between;
                    }
                    .morning {
                        width: 30px;
                        height: 30px;
                        text-align: center;
                        line-height: 30px;
                        border-radius: 50%;
                        background-color: #519770;
                        color: #ffffff;
                        margin-left: 26px;
                        margin-right: 10px;
                        vertical-align: middle;
                    }
                    img {
                        width: 26px;
                        height: 23px;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}
// 日历
::v-deep .el-calendar__body {
    padding: 0px;
}
::v-deep .el-calendar-table thead th {
    padding: 5px;
}
::v-deep .el-calendar-table .el-calendar-day:hover {
    background: none;
    color: none;
}
::v-deep .el-calendar-table td.is-selected {
    background: #519770;
    color: #ffffff;
}
::v-deep .el-calendar-table .el-calendar-day {
    height: 29px;
    text-align: center;
    line-height: 13px;
}
// 下拉框
::v-deep .el-input__inner {
    width: 120px;
    border: none;
    margin: 7px 0px 0px 15px;
    letter-spacing: 2px;
    color: #519770;
}

::v-deep .el-select .el-input .el-select__caret {
    margin-top: 2px;
    color: #519770;
}
</style>