<template>
    <div class="back">
        <div class="content">
            <span class="content-text">绵阳市安州区教务管理</span>
            <div class="content-div">
                <div class="content-div-left">
                    <div style="margin-top: 130px">
                        <span class="Welcome">Welcome ！</span>
                        <span class="Welcometext"
                            >绵阳市安州区校园晨检平台</span
                        >
                    </div>
                </div>
                <div class="content-div-right">
                    <span class="welcome">欢迎登录</span>
                    <div class="input">
                        <div>
                            <input
                                type="text"
                                placeholder="用户名"
                                class="zhanghao"
                                v-model="phone"
                                maxlength="11"
                            />
                        </div>
                        <div style="margin-top: 20px; margin-bottom: 10px">
                            <input
                                type="password"
                                placeholder="密码"
                                class="mima"
                                v-model="password"
                                maxlength="18"
                            />
                        </div>
                        <div class="register" v-if="false">
                            <span>忘记密码？</span>
                            <span>注册</span>
                        </div>
                        <div class="denglu" @click="logenghome">登 录</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            phone: "12333333335",
            password: "33335",
        };
    },
    methods: {
        logenghome: _.throttle(
            function () {
                this.logenghomeThrottle();
            },
            3000,
            {
                leading: true,
                trailing: false,
            }
        ),
        logenghomeThrottle() {
            // console.log(this.phone, "password");
            if (this.password.trim().length == 0) {
                this.$message.error("请输入密码！");
                return;
            }
            var data = {
                LOGIN_NAME: this.phone,
                LOGIN_PASSWORD: this.password,
            };
            this.$api.login(data, (res) => {
                console.log(res, "res登录");
                if (res.data.state) {
                    localStorage.setItem(
                        "userInfo",
                        JSON.stringify(res.data.Data[0])
                    );
                    this.$globle.userInfo = JSON.stringify(res.data.Data[0]);
                    localStorage.setItem("token", res.headers.token);
                    localStorage.setItem("warninglength", 0);
                    console.log(res.headers, "res.headers");
                    // this.$globle.login = true;
                    // this.$router.push("/classroomCheck");
                    this.$router.push("/homePage");
                    this.$message({
                        message: "登录成功！",
                        type: "success",
                    });
                    setTimeout(() => {
                        location.reload();
                    }, 100);

                    // this.$router.push("/facultyCheck");
                } else {
                    this.$message.error("账号或密码错误，登录失败！");
                    return;
                }
            });
        },
    },
};
</script>

<style scoped lang='scss'>
@import "../../style/_style.scss";
.back {
    width: 100%;
    height: 100vh;
    background-image: url("../../assets/登录/schoollogin.png");
    background-size: 100%;
    padding: 10px;
    box-sizing: border-box;
}
.content {
    width: 60%;
    margin: 8% auto;
}
.content-text {
    width: 100%;
    height: 30px;
    line-height: 30px;
    color: #1687ce;
    font-size: 12px;
    letter-spacing: 2px;
}
.content-div {
    width: 100%;
    height: 400px;
    margin: 0 auto;
    border: 0.5px solid #ffffff;
    border-radius: 8px;
    display: flex;
    .content-div-left {
        width: 50%;
        height: 100%;
        span {
            display: inline-block;
            width: 100%;
            font-size: 20.5px;
            color: #1687ce;
            box-sizing: border-box;
            padding-left: 60px;
            margin-top: 20px;
        }
    }
    .content-div-right {
        width: 50%;
        height: 100%;
        background-color: rgba($color: #ffffff, $alpha: 0.5);
        border-radius: 7px;
        .welcome {
            display: inline-block;
            width: 100%;
            text-align: center;
            height: 100px;
            line-height: 100px;
            color: $login;
            font-size: 20px;
            font-weight: bold;
        }
        .input {
            margin: 0 auto;
            width: 282px;
        }
        input {
            outline: none;
            width: 282px;
            height: 40px;
            border: 0.5px solid #aaaaaa;
            border-radius: 4px;
            background-repeat: no-repeat;
            box-sizing: border-box;
            padding-left: 32px;
            background-position: 7px 9px;
            font-size: 12px;
            color: #4b4141;
            background-color: rgba($color: #ffffff, $alpha: 0.5);
            letter-spacing: 1px;
        }
        .zhanghao {
            background-image: url("../../assets/登录/蒙版组 583@2x.png");
        }
        .mima {
            background-image: url("../../assets/登录/蒙版组 584@2x.png");
        }
    }
}
input::-webkit-input-placeholder {
    /* placeholder颜色  */
    color: #acacac;
}
// 注册
.register {
    width: 100%;
    color: #2f87c1;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
}
.denglu {
    width: 240px;
    height: 30px;
    line-height: 30px;
    margin: 0 auto;
    text-align: center;
    color: #ffffff;
    background: #2f87c1;
    border-radius: 4px;
    margin-top: 60px;
}
.Welcome {
    font-size: 30px !important;
    letter-spacing: 3px;
    font-weight: 600;
}
.Welcometext {
    font-size: 25px !important;
    letter-spacing: 3px;
    font-weight: 600;
}
</style>