<template>
    <div class="div">
        <el-container v-if="$route.meta.keepAlive">
            <el-header height="77px">
                <Top></Top>
            </el-header>
            <el-container style="height: 100%" class="scrollbar">
                <el-aside width="180px">
                    <Left></Left>
                </el-aside>
                <el-main>
                    <router-view :MqttType="MqttType"></router-view>
                </el-main>
            </el-container>
        </el-container>
        <!-- 预警弹窗 -->
        <div class="dialog">
            <!-- 预警  弹窗 -->
            <el-dialog
                title=""
                :visible.sync="dialogVisible"
                width="570px"
                :before-close="handleClose"
            >
                <div
                    :class="
                        warningdata.Level == '一级'
                            ? 'alert'
                            : '' || warningdata.Level == '二级'
                            ? 'alerterji'
                            : '' || warningdata.Level == '三级'
                            ? 'alertsanji'
                            : '' || warningdata.Level == '四级'
                            ? 'alertsiji'
                            : ''
                    "
                >
                    <div class="alert-illness">
                        {{ warningdata.DISEASE_NAME }}
                    </div>
                    <div class="alert-title">
                        {{ warningdata.ORG_NAME }}{{ warningdata.REGION_NAME }}
                    </div>
                    <div class="alert-read">
                        <span
                            :class="
                                warningdata.Level == '一级'
                                    ? 'alert-readspan'
                                    : '' || warningdata.Level == '二级'
                                    ? 'alert-readspanerji'
                                    : '' || warningdata.Level == '三级'
                                    ? 'alert-readspansanji'
                                    : '' || warningdata.Level == '四级'
                                    ? 'alert-readspansiji'
                                    : ''
                            "
                            @click="readspan"
                            >已 读</span
                        >
                    </div>
                    <div class="alert-date">{{ warningdata.date }}</div>
                </div>
            </el-dialog>
        </div>
        <!-- 登录，注册 -->
        <div v-if="$route.meta.keepAlive == false">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import Top from "./Top.vue";
import Left from "./Left.vue";
import globle from "@/components/globle.js";
import {
    createConnect,
    endConnect,
    unsubscribe,
    doPublish,
} from "../mqtt/mqtt";
export default {
    data() {
        return {
            property: "value",
            // 预警弹出框
            dialogVisible: false,
            // 预警弹出框 判断
            warning: false,
            // 预警值
            warningdata: {},
            earlywarning: "0",
            // 判断MQTT触发类型(学生状态)
            MqttType: "",
        };
    },
    components: {
        Top,
        Left,
    },
    beforeMount() {
        if (this.$route.path == "/login") {
        } else {
            // 获取数据字典
            var data = { ORG_ID: "temp" };
            this.$api.dictionary(data, (res) => {
                if (res.state) {
                    this.$globle.Dictionaries = res.Data;
                }
            });
            this.dictionary();
        }
    },
    created() {
        //链接mqtt
        this.Mqtt();
    },
    methods: {
        async dictionary() {
            var data2 = {
                ORG_ID: JSON.parse(localStorage.getItem("userInfo"))
                    ? JSON.parse(localStorage.getItem("userInfo"))
                          .PARENT_ORG_INFO.ID
                    : "",
                REGION: JSON.parse(localStorage.getItem("userInfo"))
                    ? JSON.parse(localStorage.getItem("userInfo"))
                          .PARENT_ORG_INFO.REGION
                    : "",
            };
            //  获取预警列表
            await this.$api.QueryWarningReleaseRecordForschool(data2, (res) => {
                if (res.state) {
                    if (res.Data.length != 0) {
                        res.Data.map((item) => {
                            item.Level = this.$globle.getmessageID(
                                item.LEVEL
                            ).CONTENT;
                        });
                        this.warningdata = res.Data[0];
                        this.warningdata.date = this.$date.Datetimestamp2(
                            res.Data[0].CTIME
                        );
                        if (
                            localStorage.getItem("warninglength") <
                            res.Data.length
                        ) {
                            setTimeout(() => {
                                this.dialogVisible = true;
                            }, 1500);
                        }
                        localStorage.setItem("warninglength", res.Data.length);
                    }
                }
            });
        },
        // 是否返回主页面
        Clickswitch() {
            this.switchdata = !this.switchdata;
        },
        handleClose(done) {
            this.$confirm("确认关闭？")
                .then((_) => {
                    done();
                    if (this.$route.path == "/warninglist") {
                        location.reload();
                    } else {
                        this.$router.push("/warninglist");
                    }
                })
                .catch((_) => {});
        },
        // 预警已读
        readspan() {
            // console.log("预警已读");
            var data = {
                ORG_ID: JSON.parse(localStorage.getItem("userInfo"))
                    .PARENT_ORG_INFO.ID,
                WARNING_RELEASE_RECORD_ID: this.warningdata.ID,
                CID: JSON.parse(localStorage.getItem("userInfo")).ID,
            };
            this.$api.Readspan(data, (res) => {
                // console.log(res, "已阅");
                if (res.state) {
                    this.$message({
                        message: "成功！",
                        type: "success",
                    });
                    this.dialogVisible = false;
                    if (this.$route.path == "/warninglist") {
                        location.reload();
                    } else {
                        this.$router.push("/warninglist");
                    }
                } else {
                    this.$message.error("失败！");
                }
            });
        },
        // mqtt连接
        Mqtt() {
            // console.log("Mqtt测试");
            let message = "message";
            // 订阅当前设备的消息   主题  ReleaseWarning
            createConnect(
                ["ReleaseWarning", "StudentStatus"],
                (topicres, message) => {
                    globle.styledata = message;
                    if (message == "有一条预警发布") {
                        //  获取预警列表
                        var data2 = {
                            ORG_ID: JSON.parse(localStorage.getItem("userInfo"))
                                .PARENT_ORG_INFO.ID,
                            REGION: JSON.parse(localStorage.getItem("userInfo"))
                                .PARENT_ORG_INFO.REGION,
                        };
                        this.$api.QueryWarningReleaseRecordForschool(
                            data2,
                            (res) => {
                                if (res.state) {
                                    if (res.Data.length != 0) {
                                        res.Data.map((item) => {
                                            item.Level =
                                                this.$globle.getmessageID(
                                                    item.LEVEL
                                                ).CONTENT;
                                        });
                                        this.warningdata = res.Data[0];
                                        this.warningdata.date =
                                            this.$date.Datetimestamp2(
                                                res.Data[0].CTIME
                                            );
                                        this.dialogVisible = true;
                                        localStorage.setItem(
                                            "warninglength",
                                            res.Data.length
                                        );
                                    }
                                }
                            }
                        );
                    } else if (message == "有一条学生状态") {
                        let date = Math.round(new Date() / 1000);
                        this.MqttType = date + message;
                    }
                }
            );
        },
    },
};
</script>


<style scoped lang='scss'>
@import "../style/_style.scss";
.div {
    width: 100%;
    height: 100vh;
}
.el-header,
.el-footer {
    background-color: #b3c0d1;
    color: #333;
    text-align: center;
    padding: 0px;
}
.el-aside {
    background-color: #ffffff;
    color: #333;
    text-align: center;
}
.el-main {
    background-color: #e9eef3;
    color: #333;
    // height: calc(100vh - 140px);
}
body > .el-container {
    margin-bottom: 40px;
}
.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
    line-height: 260px;
}
.el-container:nth-child(7) .el-aside {
    line-height: 320px;
}
.el-container {
    height: 100%;
}
.el-main {
    padding: 10px 10px 0px 10px;
}
/* 预警弹窗 */
.dialog ::v-deep .el-dialog__header {
    padding: 0px;
}
.dialog ::v-deep .el-dialog__body {
    padding: 0px;
}
.dialog ::v-deep .el-dialog__headerbtn .el-dialog__close {
    font-size: 40px;
    font-weight: bold;
    color: #f23540;
}
.dialog ::v-deep .el-dialog {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    box-shadow: none;
}
/* 一级 */
.dialog .alert {
    width: 100%;
    height: 570px;
    letter-spacing: 4px;
    background-image: url("../assets/预警弹窗/jiyiji.png");
    background-size: cover;
    box-sizing: border-box;
    padding: 150px 60px 0px 80px;
}
/* 二级 */
.dialog .alerterji {
    width: 100%;
    height: 570px;
    letter-spacing: 4px;
    background-image: url("../assets/预警弹窗/jierji.png");
    background-size: cover;
    box-sizing: border-box;
    padding: 150px 60px 0px 80px;
}
/* 三级级 */
.dialog .alertsanji {
    width: 100%;
    height: 570px;
    letter-spacing: 4px;
    background-image: url("../assets/预警弹窗/jisanji.png");
    background-size: cover;
    box-sizing: border-box;
    padding: 150px 60px 0px 80px;
}
/* 四级 */
.dialog .alertsiji {
    width: 100%;
    height: 570px;
    letter-spacing: 4px;
    background-image: url("../assets/预警弹窗/jisiji.png");
    background-size: cover;
    box-sizing: border-box;
    padding: 150px 60px 0px 80px;
}
.alert-title {
    font-weight: bold;
    font-size: 36px;
    text-align: center;
    height: 116px;
    line-height: 90px;
    color: #ffffff;
}
.alert-illness {
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    line-height: 130px;
    font-size: 31px;
    // 文字超出变省略号
    white-space: nowrap; /* 不换行 */
    overflow: hidden; /* 超出隐藏 */
    text-overflow: ellipsis; /* 超出部分显示省略号 */
}
.cumulative {
    text-align: center;
    line-height: 65px;
    font-size: 18px;
    color: #ffffff;
}
.cumulative-day {
    display: inline-block;
    height: 51px;
    line-height: 65px;
    margin-right: 5px;
    font-size: 30px;
    color: #ffffff;
    font-weight: bold;
}
/* 一级 */
.cumulative-example {
    display: inline-block;
    font-size: 30px;
    width: 51px;
    height: 51px;
    text-align: center;
    line-height: 51px;
    background: #ffffff;
    color: $gender1;
    font-weight: bold;
    border-radius: 50%;
}
/* 二级 */
.cumulative-exampleerji {
    display: inline-block;
    font-size: 30px;
    width: 51px;
    height: 51px;
    text-align: center;
    line-height: 51px;
    background: #ffffff;
    color: $gender2;
    font-weight: bold;
    border-radius: 50%;
}
/* 三级 */
.cumulative-examplesanji {
    display: inline-block;
    font-size: 30px;
    width: 51px;
    height: 51px;
    text-align: center;
    line-height: 51px;
    background: #ffffff;
    color: $gender3;
    font-weight: bold;
    border-radius: 50%;
}
/* 四级 */
.cumulative-examplesiji {
    display: inline-block;
    font-size: 30px;
    width: 51px;
    height: 51px;
    text-align: center;
    line-height: 51px;
    background: #ffffff;
    color: $gender4;
    font-weight: bold;
    border-radius: 50%;
}
.alert-earlywarning {
    font-weight: bold;
    text-align: center;
    line-height: 90px;
    font-size: 32px;
    color: #ffffff;
}
.alert-read {
    text-align: center;
}
// 一级
.alert-readspan {
    display: inline-block;
    width: 180px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background: #ffffff;
    color: $gender1;
    font-size: 14px;
    border-radius: 5px;
    margin-top: 38px;
}
// 二级
.alert-readspanerji {
    display: inline-block;
    width: 180px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background: #ffffff;
    color: $gender2;
    font-size: 14px;
    border-radius: 5px;
    margin-top: 38px;
}
// 三级
.alert-readspansanji {
    display: inline-block;
    width: 180px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background: #ffffff;
    color: $gender3;
    font-size: 14px;
    border-radius: 5px;
    margin-top: 38px;
}
// 四级
.alert-readspansiji {
    display: inline-block;
    width: 180px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background: #ffffff;
    color: $gender4;
    font-size: 14px;
    border-radius: 5px;
    margin-top: 38px;
}
.alert-date {
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-size: 18px;
    margin-top: 38px;
    letter-spacing: 2px !important;
}
::v-deep .el-message-box {
    margin-top: 50px;
}
</style>