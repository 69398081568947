<template>
    <div class="container">
        <!-- left -->
        <!-- <div class="containerin">
            <LeiftTree></LeiftTree>
        </div> -->
        <!-- right -->
        <div class="containerright">
            <Rightlist
                :switchdate="switchdate"
                :STATGEIDclick="STATGEIDclick"
                :DataList="DataList"
            ></Rightlist>
        </div>
    </div>
</template>

<script>
import LeiftTree from "../../components/check/LeiftTree.vue";
import Rightlist from "../../components/check/Rightlist.vue";
export default {
    props: {
        // 在props中接收父组件传过来的值
        MqttType: "",
    },
    components: {
        LeiftTree,
        Rightlist,
    },

    data() {
        return {
            date: "",
            STATGEID: "ALL",
            Type: "教育阶段",
            DataList: [],
        };
    },
    beforeMount() {},
    methods: {
        // 点击切换当天日期
        switchdate(e) {
            this.date = e;
            this.QueryClassroomAttendanceDynamicData();
        },
        // 数据列表
        QueryClassroomAttendanceDynamicData() {
            let date = new Date(this.date);

            var data = {
                SCHOOLID: this.$globle.userInfo.PARENT_ORG_INFO.ID,
                STARTTIME: this.$date.Datetimestamp(date).datefront,
                ENDTIME: this.$date.Datetimestamp(date).dateafter,
            };
            if (this.Type == "教育阶段") {
                // console.log("教育阶段");
                data.STATGEID = this.STATGEID;
            } else if (this.Type == "年级") {
                // console.log("年级");
                data.GRADEID = this.STATGEID;
            } else if (this.Type == "班级") {
                // console.log("班级");
                data.CLASSID = this.STATGEID;
            }
            if (data.STATGEID || data.GRADEID || data.CLASSID) {
            } else {
                return;
            }
            // 动态数据  课堂考勤
            this.$api.QueryClassroomAttendanceDynamicData(data, (res) => {
                console.log(res, data, "动态数据");
                if (res.state) {
                    this.DataList = res.Data;
                }
            });
        },
        // 获取动态数据
        STATGEIDclick(e) {
            this.STATGEID = e.ID;
            this.Type = this.$globle.getmessageID(e.ORG_TYPE).CONTENT;
            this.QueryClassroomAttendanceDynamicData();
        },
        // 预警弹窗
        handleClose(done) {
            this.$confirm("确认关闭？")
                .then((_) => {
                    done();
                    if (this.$route.path == "/warninglist") {
                        location.reload();
                    } else {
                        this.$router.push("/warninglist");
                    }
                })
                .catch((_) => {});
        },
        // 预警已读
        readspan() {
            console.log("预警已读");
            var data = {
                ORG_ID: JSON.parse(localStorage.getItem("userInfo"))
                    .PARENT_ORG_INFO.ID,
                WARNING_RELEASE_RECORD_ID: this.warningdata.ID,
                CID: JSON.parse(localStorage.getItem("userInfo")).ID,
            };
            this.$api.Readspan(data, (res) => {
                console.log(res, "已阅");
                if (res.state) {
                    this.$message({
                        message: "成功！",
                        type: "success",
                    });
                    this.dialogVisible = false;
                    if (this.$route.path == "/warninglist") {
                        location.reload();
                    } else {
                        this.$router.push("/warninglist");
                    }
                } else {
                    this.$message.error("失败！");
                }
            });
        },
    },
    // 监听 mqtt 消息获取课堂考勤最新数据
    watch: {
        MqttType(newVal, oldVal) {
            this.QueryClassroomAttendanceDynamicData();
            // console.log("newVal", newVal, "新");
            // console.log("oldVal", oldVal, "旧");
        },
    },
};
</script>

<style scoped lang='scss'>
@import "../../style/_style.scss";
.container {
    display: flex;
    letter-spacing: 2px;
    height: calc(100vh - 100px);
}
.containerin {
    height: 100%;
    box-shadow: 0rpx 0rpx 24rpx 0rpx rgb(233, 233, 233);
    border-radius: 10px;
    background-color: #ffffff;
    min-height: 1000px;
    min-width: 350px;
    max-width: 350px;
    margin: 0px 12px 0px 0px;
}
.containerright {
    height: 100%;
    box-shadow: 0rpx 0rpx 24rpx 0rpx rgb(233, 233, 233);
    border-radius: 10px;
    min-height: 1000px;
    min-width: 785px;
    position: relative;
    box-sizing: border-box;
    flex-grow: 1;
}
//
.containerinleft {
    width: 100px;
    height: 200px;
    text-align: center;
    line-height: 200px;
}
</style>