var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"div"},[(_vm.$route.meta.keepAlive)?_c('el-container',[_c('el-header',{attrs:{"height":"77px"}},[_c('Top')],1),_c('el-container',{staticClass:"scrollbar",staticStyle:{"height":"100%"}},[_c('el-aside',{attrs:{"width":"180px"}},[_c('Left')],1),_c('el-main',[_c('router-view',{attrs:{"MqttType":_vm.MqttType}})],1)],1)],1):_vm._e(),_c('div',{staticClass:"dialog"},[_c('el-dialog',{attrs:{"title":"","visible":_vm.dialogVisible,"width":"570px","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{class:_vm.warningdata.Level == '一级'
                        ? 'alert'
                        : '' || _vm.warningdata.Level == '二级'
                        ? 'alerterji'
                        : '' || _vm.warningdata.Level == '三级'
                        ? 'alertsanji'
                        : '' || _vm.warningdata.Level == '四级'
                        ? 'alertsiji'
                        : ''},[_c('div',{staticClass:"alert-illness"},[_vm._v(" "+_vm._s(_vm.warningdata.DISEASE_NAME)+" ")]),_c('div',{staticClass:"alert-title"},[_vm._v(" "+_vm._s(_vm.warningdata.ORG_NAME)+_vm._s(_vm.warningdata.REGION_NAME)+" ")]),_c('div',{staticClass:"alert-read"},[_c('span',{class:_vm.warningdata.Level == '一级'
                                ? 'alert-readspan'
                                : '' || _vm.warningdata.Level == '二级'
                                ? 'alert-readspanerji'
                                : '' || _vm.warningdata.Level == '三级'
                                ? 'alert-readspansanji'
                                : '' || _vm.warningdata.Level == '四级'
                                ? 'alert-readspansiji'
                                : '',on:{"click":_vm.readspan}},[_vm._v("已 读")])]),_c('div',{staticClass:"alert-date"},[_vm._v(_vm._s(_vm.warningdata.date))])])])],1),(_vm.$route.meta.keepAlive == false)?_c('div',[_c('router-view')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }